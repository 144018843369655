import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { getGoogleAPIAuthGetRedirectUrl, getGoogleAPIAuthStatus } from 'services/auth/auth';
import { clearFormError } from 'services/errors/errorSlice';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

import GCloudPermissionsChecker from './components/GCloudPermissionsChecker';

const GoogleAccessContent = ({ isAuthorized, googleAPIAuthRegisterErrors, handleGoogleLogin }) => (
  <>
    <div className="sm:mx-auto">
      <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-6">
        Google Access
      </h2>
      {isAuthorized ? (
        <p className="text-center mt-4">
          Thank you for granting us Google access. If you need to re-authorize, you can use the
          button below.
        </p>
      ) : (
        <>
          <p>We need some permissions to auto fill our forms and work with your data.</p>
          <p>Specifically we&apos;re asking for permissions to GA4 and BigQuery.</p>
          <p>If you&apos;d prefer to manage this through a service account please get in touch.</p>
        </>
      )}
    </div>
    {googleAPIAuthRegisterErrors.length > 0 && (
      <div className="bg-red-100 border border-red-400 text-red-700 rounded p-4 mt-4">
        {googleAPIAuthRegisterErrors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
      </div>
    )}
    <div className=" sm:mx-auto space-y-6">
      <Button
        onClick={handleGoogleLogin}
        variant="primary"
        className="w-full"
        statusColour={isAuthorized ? 'success' : ''}
      >
        {isAuthorized ? 'Re-authorize Google Access' : 'Share Access'}
      </Button>
    </div>
  </>
);

GoogleAccessContent.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  googleAPIAuthRegisterErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleGoogleLogin: PropTypes.func.isRequired,
};

const GoogleAPIAuthRegister = () => {
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Get any errors that were sent to redux
  const formErrors = useSelector((state) => state.error.formErrors);
  const googleAPIAuthRegisterErrors = formErrors?.googleAPIAuthRegister || [];

  /**
   * Begin the oauth2 flow.
   */
  const handleGoogleLogin = async () => {
    dispatch(clearFormError('googleAPIAuthRegister'));
    try {
      const redirectUrl = await getGoogleAPIAuthGetRedirectUrl();
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Failed to get Google API auth redirect URL:', error);
    }
  };

  // Check to see if we're authorized and provide feedback to user.
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const status = await getGoogleAPIAuthStatus();
        setIsAuthorized(status?.is_authenticated);
      } catch (error) {
        console.error('Failed to get Google API auth status:', error);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">
            Share permission with us
          </h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="bg-white shadow sm:rounded-lg w-2/6">
            <div className="px-4 py-5 sm:p-6">
              <GoogleAccessContent
                isAuthorized={isAuthorized}
                googleAPIAuthRegisterErrors={googleAPIAuthRegisterErrors}
                handleGoogleLogin={handleGoogleLogin}
              />
            </div>
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">
            Does your account have the right permissions?
          </h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="">
            <GCloudPermissionsChecker />
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default GoogleAPIAuthRegister;
