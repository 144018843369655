/**
 * Hook to manage addon quantity changes
 */
import { useState } from 'react';

export const useAddonQuantities = (subscription) => {
  const [addonChanges, setAddonChanges] = useState({});

  const handleQuantityChange = (priceId, newQuantity, currentQuantity) => {
    if (newQuantity !== currentQuantity) {
      setAddonChanges((prev) => ({
        ...prev,
        [priceId]: newQuantity,
      }));
    } else {
      setAddonChanges((prev) => {
        const newChanges = { ...prev };
        delete newChanges[priceId];
        return newChanges;
      });
    }
  };

  const calculateNewTotal = (baseTotal) => {
    let total = baseTotal;

    // Only adjust total if there are changes
    Object.entries(addonChanges).forEach(([priceId, quantity]) => {
      const item = subscription.items.find((item) => item.price.id === priceId);
      if (item) {
        const currentCost = item.price.unit_price.amount * item.quantity;
        const newCost = item.price.unit_price.amount * quantity;
        total = total - currentCost + newCost;
      }
    });

    return total;
  };

  const resetChanges = () => {
    setAddonChanges({});
  };

  return {
    addonChanges,
    handleQuantityChange,
    calculateNewTotal,
    hasChanges: Object.keys(addonChanges).length > 0,
    resetChanges,
  };
};
