import { useDispatch, useSelector } from 'react-redux';

import Drawer from 'components/Drawer/Drawer';
import FieldExampleValueHelper from 'components/FieldExampleValueHelper/FieldExampleValueHelper';
import BigQueryDatasetCreate from 'pages/bigquery/BigQueryDatasetCreatePage';
import { closeDrawer } from 'services/drawers/drawerSlice';

const GlobalDrawer = () => {
  const dispatch = useDispatch();

  const contentMap = {
    CREATE_DATASET_FORM: (args) => (
      <BigQueryDatasetCreate {...args} onSuccess={() => dispatch(closeDrawer())} />
    ),
    FIELD_EXAMPLE_HELPER: (args) => <FieldExampleValueHelper fieldName={args.fieldName} />,
    // Add other content types as needed
  };

  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const drawer = useSelector((state) => state.drawer || {});

  // Destructure after hooks, with defaults
  const { isOpen = false, content = null, args = {} } = drawer;
  const ContentComponent = content ? contentMap[content] : null;

  if (!refreshToken) {
    return null;
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      title={content ? content.replace(/_/g, ' ') : ''}
    >
      {ContentComponent && <ContentComponent {...args} />}
    </Drawer>
  );
};

export default GlobalDrawer;
