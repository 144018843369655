import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FormPropertyList from 'components/Forms/FormPropertyList/FormPropertyList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import {
  fieldInfoSourceGA4DBTUserProperties,
  sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
  sourceGA4BigQueryUserPropertiesNumberSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageUserProperties/schema/sourceGA4DBTUserPropertiesSchema';
import { useGetDbtProjectFileQuery } from 'services/rtkApi';

import useGA4UserPropertyTemplates, {
  emptyUserTemplateBool,
  emptyUserTemplateStringNumber,
} from '../hooks/useGA4UserPropertyTemplates';
import SourceGA4BigQueryFormSingleUserProperty from './SourceGA4BigQueryFormSingleUserProperty';

// Define a lookup constant for schemas
const SCHEMA_LOOKUP = {
  default: {
    schema: sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
  string_number: {
    schema: sourceGA4BigQueryUserPropertiesNumberSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
  bool: {
    schema: sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
};

/**
 * SourceGA4BigQueryFormAllUserProperties component for managing all user properties.
 */
const SourceGA4BigQueryFormAllUserProperties = () => {
  const { id, pipelineId } = useParams();

  // Go and get table schema for the form.
  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId);

  // Get the form data
  const { data: formData, isLoading: isLoadingForm, error } = useGetDbtProjectFileQuery(id);

  // Extract conversion_events and default to an empty array
  const user_properties = formData?.derived_user_properties || [];

  /**
   * Get actions and state with the actions.
   */
  const { emptyStateActions, handleAddUserProperty, handleDeleteUserProperty, isUpdating } =
    useGA4UserPropertyTemplates(id, user_properties);

  // Update items to include the schemaType
  const items = useMemo(() => {
    return user_properties.map((prop, index) => ({
      id: index,
      name: prop.name,
      index,
      schemaType: prop.schemaType || 'default',
    }));
  }, [user_properties]);

  /**
   * Bottom actions to be shown below the templates.
   */
  const bottomActions = [
    {
      text: 'Add user property (string/number)',
      description: "Add a user property like 'total lifetime value' or 'favorite product category'",
      action: () => handleAddUserProperty(emptyUserTemplateStringNumber),
    },
    {
      text: 'Add user property (true/false)',
      description: "Add a user property like 'is premium member' or 'has completed onboarding'",
      action: () => handleAddUserProperty(emptyUserTemplateBool),
    },
  ];

  /**
   * Define schema options
   */

  if (isLoadingForm || isUpdating) return <LoadingSpinner text="Loading user properties..." />;
  if (error) return <p>Error loading data</p>;

  return (
    <FormPropertyList
      items={items}
      emptyStateTitle="Add user property"
      emptyStateDescription="Add a user property to get started."
      emptyStateActions={emptyStateActions}
      emptyProjectText="Add custom user property"
      emptyProjectAction={() =>
        handleAddUserProperty({ name: 'New User Property', propertyConditionBlock: {} })
      }
      onDeleteItem={handleDeleteUserProperty}
      bottomActions={bottomActions}
      isLoading={isUpdating}
    >
      {(selectedItem) => {
        const { schema, fieldInfo } =
          SCHEMA_LOOKUP[selectedItem.schemaType] || SCHEMA_LOOKUP.default;

        return (
          <SourceGA4BigQueryFormSingleUserProperty
            id={id}
            pipelineId={pipelineId}
            pipelineData={pipelineData}
            tableSchema={tableSchema}
            isLoadingTableSchema={isLoadingTableSchema}
            selectedPropertyIndex={selectedItem.index}
            allProperties={user_properties}
            schema={schema}
            fieldInfo={fieldInfo}
          />
        );
      }}
    </FormPropertyList>
  );
};

export default SourceGA4BigQueryFormAllUserProperties;
