import Button from 'components/Button/Button';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';

/**
 * Page descriptions and documentation links for GA4 BigQuery form pages
 * @type {Object.<string, {title: string, description: string[], docLink: string}>}
 */
const GA4_PAGE_DESCRIPTIONS = {
  main: {
    title: 'Getting Started',
    description: [
      "Let's get started! Pick the GA4 property you want to add.",
      "You can add multiple properties and we'll roll them all up into one dataset. You'll typically only want to do this if you're using multiple properties for the same site.",
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-required-setup/adding-ga4-to-your-pipeline',
  },

  ecommerce: {
    title: 'What is it?',
    description: [
      'Are you an ecommerce site? If so you can use this section to extract ecommerce data from your GA4 data.',
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/ecommerce',
  },

  advanced: {
    title: 'Advanced Settings',
    description: [
      'Configure advanced settings for your GA4 BigQuery pipeline.',
      'These settings allow you to fine-tune how your GA4 data is processed and stored.',
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/advanced-configuration',
  },

  columnsToUnnest: {
    title: 'Event Parameters',
    description: [
      "There is a lot of useful data in GA4. Here we're going to extract any information we want from our event parameters.",
      "If you want a piece of data for a conversion, session property or anything else you need to take it here! (Although don't worry you can come back.)",
      "You'll see a sampling of all the available options below the form. Add any parameters you're interested in to the form.",
      'Once you\'ve done this you\'ll need to "Run initial data setup". This might take up to 30 minutes depending on the size of your GA4 propery.',
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/extracting-extra-data-for-ga4',
  },
  conversions: {
    title: 'Conversions',
    description: ['Configure your conversions here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/conversion-events',
  },
  spamFilter: {
    title: 'Spam Filter',
    description: ['Configure your spam filter here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/spam-filter',
  },
  channelGroupings: {
    title: 'Channel Groupings',
    description: ['Configure your channel groupings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/channel-groupings',
  },
  sessionProperties: {
    title: 'Session Properties',
    description: ['Configure your session properties here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/session-properties',
  },
  userProperties: {
    title: 'User Properties',
    description: ['Configure your user properties here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/user-properties',
  },
  advancedBQ: {
    title: 'Advanced BQ',
    description: ['Configure your advanced BQ settings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/advanced-bq',
  },
  attribution: {
    title: 'Attribution',
    description: ['Configure your attribution settings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/attribution',
  },
};

/**
 * Renders a standardized description section for GA4 BigQuery pages
 * @param {Object} props
 * @param {string} props.pageKey - Key to lookup in GA4_PAGE_DESCRIPTIONS
 */
const SourceGA4BigQueryPageDescription = ({ pageKey }) => {
  const pageData = GA4_PAGE_DESCRIPTIONS[pageKey];

  if (!pageData) return null;

  return (
    <SourceGA4BigQueryFormSection isBottomBorder>
      <div className="text-gray-600 text-sm flex items-center">
        <div className="flex justify-between items-center mb-4 w-full">
          <div>
            {pageData.title && (
              <p>
                <strong>{pageData.title}</strong> {pageData.description[0]}
              </p>
            )}
            {pageData.description.slice(1).map((desc, index) => (
              <p key={index}>{desc}</p>
            ))}
          </div>
          <Button href={pageData.docLink} variant="primary" isOutline openInNewTab>
            View Documentation
          </Button>
        </div>
      </div>
    </SourceGA4BigQueryFormSection>
  );
};

export default SourceGA4BigQueryPageDescription;
