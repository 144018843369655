import { useNavigate, useParams } from 'react-router-dom';

import UpgradeSourceGA4BigQueryForm from 'pages/models/source_ga4_bigquery/UpgradeSource/components/UpgradeSourceGA4BigQueryForm';
import { SOURCE_GA4_BIGQUERY_LIST } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';
import { createURLWithParams } from 'utils';

const UpgradeSourceGA4BigQueryFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const pageTitle = 'Upgrade GA4 BigQuery Source';

  const handleSuccess = (entityId) => {
    const url = createURLWithParams(SOURCE_GA4_BIGQUERY_LIST, { id: entityId });
    navigate(url);
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <UpgradeSourceGA4BigQueryForm entityId={id} onSuccess={handleSuccess} />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default UpgradeSourceGA4BigQueryFormPage;
