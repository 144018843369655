import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable';

/**
 * Displays field information and example values from the static CSV
 * @param {Object} props
 * @param {string} props.fieldName - Name of the field to display information for
 */
function FieldExampleValueHelper({ fieldName }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const csvData = await import('exampleFieldData.csv');
        setData(csvData.default); // Already parsed into JSON!
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load field data:', error);
        setData([]);
        setIsLoading(false);
      }
    }
    loadData();
  }, []);

  // Early return if data isn't an array or is empty and not loading
  if (!isLoading && (!Array.isArray(data) || data.length === 0)) {
    return <div className="text-gray-600">No field information available at this time.</div>;
  }

  // Ensure data is an array before using array methods
  const safeData = Array.isArray(data) ? data : [];

  // Filter data for specific field and get unique description
  const fieldInfo = safeData.find((row) => row.field === fieldName);
  const description = fieldInfo?.description || 'No description available';

  // Filter example values for this field
  const examples = safeData
    .filter((row) => row.field === fieldName)
    .map((row) => ({
      value: row.exampleValues || '',
    }))
    .filter((row) => row.value !== '');

  const columns = [
    {
      Header: 'Example Values',
      accessor: 'value',
      filter: { enabled: true },
    },
  ];
  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">{fieldName}</h2>
        <p className="text-gray-600">{description}</p>
      </div>

      <FlexListTable columns={columns} data={examples} hiddenColumnIds={[]} />
    </div>
  );
}

FieldExampleValueHelper.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default FieldExampleValueHelper;
