import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4DBTAttribution,
  sourceGA4BigQueryAttributionSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageAttribution/schema/sourceGA4DBTAttributionSchema';
import { SOURCE_GA4_BIGQUERY_UPDATE_FORM } from 'routes/constants';
import {
  useAddSourceGA4BigQueryMutation,
  useGetSourceGA4BigQueryByIdQuery,
  useUpdateSourceGA4BigQueryMutation,
} from 'services/rtkApi';

const SourceGA4BigQueryFormAttribution = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const navigate = useNavigate();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';
  // Get existing data for the form.
  const mutationHook = isUpdate
    ? useUpdateSourceGA4BigQueryMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = isUpdate ? useGetSourceGA4BigQueryByIdQuery : undefined;

  const handleSuccess = React.useCallback(
    (resultId) => {
      const updateUrl = SOURCE_GA4_BIGQUERY_UPDATE_FORM.replace(':pipelineId', pipelineId).replace(
        ':id',
        resultId
      );
      navigate(updateUrl);
    },
    [pipelineId, navigate]
  );

  const preSubmit = React.useCallback(
    (formData) => {
      return {
        ...formData,
      };
    },
    [pipelineId]
  );

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={sourceGA4BigQueryAttributionSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTAttribution}
      onSuccess={handleSuccess}
      // initialValues={initialValuesSourceGA4DBTProject}
      warnOnUnsavedChanges
      preSubmit={preSubmit}
    />
  );
};

export default SourceGA4BigQueryFormAttribution;
