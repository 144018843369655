import { materialDark } from '@uiw/codemirror-theme-material';
import CodeMirror from '@uiw/react-codemirror';
import { useParams } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ModelDisplay from 'components/ModelDisplay/ModelDisplay';
import { useGetCurrentUserQuery, useGetPipelineRunByIdQuery } from 'services/rtkApi';

/**
 * Displays details of a specific pipeline run
 */
function PipelineRunView() {
  const { id } = useParams();
  const { data: pipelineRun, isLoading, isError } = useGetPipelineRunByIdQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();

  if (isLoading) return <LoadingSpinner text="Loading pipeline run details..." />;
  if (isError) return <div>Error loading pipeline run</div>;

  // Only remove run_complete_full_log if user is not a superuser
  const displayData = currentUser?.is_superuser
    ? pipelineRun
    : { ...pipelineRun, run_complete_full_log: undefined };

  const fieldNames = {
    id: { name: 'ID' },
    pipeline: { name: 'Pipeline ID' },
    run: { name: 'Run Status' },
    did_run_succeed: { name: 'Succeeded' },
    created_at: { name: 'Created At' },
    is_run_in_progress: { name: 'In Progress' },
    pipeline_arguments: { name: 'Pipeline Arguments' },
    started_at: { name: 'Started At' },
    updated_at: { name: 'Updated At' },
    run_complete_full_log: { name: 'Complete Log' },
  };

  const formatters = [
    ['created_at', (value) => new Date(value).toLocaleString()],
    ['started_at', (value) => new Date(value).toLocaleString()],
    ['updated_at', (value) => new Date(value).toLocaleString()],
    ['is_run_in_progress', (value) => (value ? 'Yes' : 'No')],
    ['did_run_succeed', (value) => (value === null ? 'In Progress' : value ? 'Yes' : 'No')],
    ['pipeline_arguments', (value) => value.join(', ')],
    ['run_complete_full_log', (value) => value || 'No log available'],
  ];

  const renderers = [
    [
      'run_complete_full_log',
      (rawValue, formattedValue) => (
        <CodeMirror value={formattedValue} height="800px" theme={materialDark} editable={false} />
      ),
    ],
  ];

  const dataCustomCols = [
    {
      fields: ['run_complete_full_log'],
      cols: 1,
      showLabelAbove: true,
      labelWidth: '100%',
    },
  ];

  return (
    <div>
      <ModelDisplay
        data={displayData}
        fieldNames={fieldNames}
        formatters={formatters}
        renderers={renderers}
        numCols={1}
        labelWidth="25%"
        dataCustomCols={dataCustomCols}
      />
    </div>
  );
}

export default PipelineRunView;
