import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SQLEditorForm from 'components/SQLEditor/SQLEditorForm';
import {
  useGetPipelineByIdQuery,
  useGetPipelineGithubFileQuery,
  useUpdateGithubFileMutation,
  useUpdateSourceGA4BigQueryMutation,
  useValidateGA4CaseStatementMutation,
} from 'services/rtkApi';

/**
 * SourceGA4BigQueryChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {string} props.pipelineId - ID of the associated pipeline
 * @param {string} props.projectId - Google Cloud project ID
 * @param {string} props.dataset - BigQuery dataset name
 */
function SourceGA4BigQueryFormChannelGroupingSQL({ id, pipelineId }) {
  const [sql, setSQL] = useState('');
  const dispatch = useDispatch();

  const {
    data: pipelineData,
    isLoading: isPipelineLoading,
    error: pipelineError,
  } = useGetPipelineByIdQuery(pipelineId);

  /**
   * Get the extra channel grouping macro
   */
  const { data: extraChannelGroupingContent, isLoading: isExtraChannelGroupingLoading } =
    useGetPipelineGithubFileQuery({
      id: pipelineId,
      file_path: 'macros/extra_channel_grouping_1.sql',
      macro_name: 'default__extra_channel_grouping_1',
    });
  const [updateGithubFile] = useUpdateGithubFileMutation();

  /**
   * Set the SQL whenever it updates.
   */
  useEffect(() => {
    if (!isExtraChannelGroupingLoading) {
      const content = extraChannelGroupingContent?.content || '';
      setSQL(content);
    }
  }, [extraChannelGroupingContent, isExtraChannelGroupingLoading]);

  const highlightWords = {
    columns: ['dim__source', 'dim__medium', 'dim__campaign'],
  };

  /**
   * Update the extra channel grouping macro
   */
  const handleSubmit = async () => {
    try {
      const result = await updateGithubFile({
        id: pipelineId,
        file_path: 'macros/extra_channel_grouping_1.sql',
        content: sql,
        macro_name: 'default__extra_channel_grouping_1',
      }).unwrap();
    } catch (error) {
      console.error('Error updating channel groupings:', error);
      // Dispatch API error
      if (error.response) {
        dispatch({
          type: 'API_ERROR',
          payload: {
            status: error.response.status,
            data: error.response.data,
          },
        });
      }
    }
  };

  if (isExtraChannelGroupingLoading) {
    return <LoadingSpinner text="Loading channel groupings" size="16" />;
  }

  return (
    <div className="source-ga4-bigquery-channel-groupings">
      <SQLEditorForm
        title="GA4 BigQuery Channel Groupings"
        initialSQL={sql}
        highlightWords={highlightWords}
        validationMutation={useValidateGA4CaseStatementMutation}
        validationParams={{
          project_id: pipelineData.gcloud_project_id,
          dataset: pipelineData.bq_output_dataset,
          validation_type: 'CUSTOM_CHANNEL_GROUPING',
        }}
        onSubmit={handleSubmit}
        mutationHook={useUpdateSourceGA4BigQueryMutation}
        entityId={id}
      />
    </div>
  );
}

export default SourceGA4BigQueryFormChannelGroupingSQL;
