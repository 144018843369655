import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';
import SourceGA4BigQueryPageDescription from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryPageDescription';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormSpamFilter from 'pages/models/source_ga4_bigquery/FormPageSpamFilter/components/SourceGA4BigQueryFormSpamFilter';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * SourceGA4BigQueryFormPageChannelGroupings component for the Channel Groupings form page
 */
function SourceGA4BigQueryFormPageSpamFilter() {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate
    ? 'Update GA4 Pipeline Spam Filter'
    : 'Create GA4 Pipeline Spam Filter';

  // Mock mutation for testing
  const mockUpdateChannelGroupingsMutation = () => [
    async ({ id, sql }) => {
      console.log('Updating channel groupings:', { id, sql });
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Simulate success
      return { success: true };
    },
  ];

  const handleSuccess = () => {
    console.log('Form submitted successfully');
    // Handle what happens after successful form submission
    // Redirect or show a success message
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SourceGA4BigQueryTitle
            title={pageTitle}
            pipelineId={pipelineId}
            sourceGA4BigQueryID={id}
            showActions={isUpdate}
          />
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} />
            <div className="flex-grow mt-5">
              <SourceGA4BigQueryPageDescription pageKey="spamFilter" />
              <SourceGA4BigQueryFormSection>
                <SourceGA4BigQueryFormSpamFilter
                  pipelineId={pipelineId}
                  id={id}
                  updateMutation={mockUpdateChannelGroupingsMutation}
                  onSuccess={handleSuccess}
                />
              </SourceGA4BigQueryFormSection>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
}

export default SourceGA4BigQueryFormPageSpamFilter;
