import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import {
  useGetCurrentUserQuery,
  useGetLatestPipelineRunQuery,
  useGetPipelineByIdQuery,
  useGetSourceGA4BigQueriesQuery,
  useRunPipelineMutation,
  useUpdatePipelineDbtProjectMutation,
} from 'services/rtkApi';

/**
 * Renders a button strip for pipeline actions, including "Manually Run Pipeline" and "Update dbt Project".
 * @param {Object} props - Component props
 * @param {number} props.pipelineId - ID of the pipeline
 * @param {boolean} props.isDisabled - Whether the action should be disabled
 * @param {string} props.className - Additional CSS classes for the container
 */
const PipelineActionStrip = ({ pipelineId, isDisabled, className }) => {
  const [runPipeline, { isLoading }] = useRunPipelineMutation();
  const [updatePipelineDbtProject, { isLoading: isUpdatingDbtProject }] =
    useUpdatePipelineDbtProjectMutation();
  const { data: user } = useGetCurrentUserQuery();

  // Get GA4 sources and if there are none, disable the run pipeline button
  const {
    data: ga4Sources,
    error: ga4Error,
    isLoading: isLoadingGA4Sources,
  } = useGetSourceGA4BigQueriesQuery(pipelineId ? { pipeline: pipelineId } : {});
  const hasNoGA4Sources =
    !isLoadingGA4Sources && (!ga4Sources?.results || ga4Sources.results.length === 0);

  // Get the latest pipeline run to see if it's already running
  const { data: latestPipelineRun, isLoading: isLoadingLatestRun } =
    useGetLatestPipelineRunQuery(pipelineId);
  const isPipelineRunning = latestPipelineRun?.is_run_in_progress;

  const handleRunPipeline = async () => {
    try {
      await runPipeline({ id: pipelineId, run_type: 'ga4' });
      // Handle success (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to run pipeline:', error);
    }
  };

  const handleUpdateDbtProject = async () => {
    try {
      await updatePipelineDbtProject(pipelineId).unwrap();
      // Handle successful update (e.g., show a success message)
    } catch (error) {
      console.error('Failed to update dbt project:', error);
    }
  };

  const { id } = useParams();
  const { data: pipeline, isLoading: isLoadingPipeline } = useGetPipelineByIdQuery(id);

  const getBigQueryUrl = () => {
    if (!pipeline?.gcloud_project_id || !pipeline?.bq_output_dataset) return null;
    return `https://console.cloud.google.com/bigquery?ws=!1m4!1m3!3m2!1s${pipeline.gcloud_project_id}!2s${pipeline.bq_output_dataset}`;
  };

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="flex items-center space-x-4">
        <h4 className="text-sm font-medium w-24">Process</h4>
        <div className="flex space-x-4">
          <Button
            onClick={handleRunPipeline}
            loading={isLoading}
            disabled={isDisabled || isLoading || isPipelineRunning || hasNoGA4Sources}
            variant="primary"
            isOutline
          >
            {isPipelineRunning ? 'Pipeline Running...' : 'Manually Run Pipeline'}
          </Button>
          {user?.is_superuser && (
            <Button
              onClick={handleUpdateDbtProject}
              loading={isUpdatingDbtProject}
              disabled={isUpdatingDbtProject}
              variant="primary"
              isOutline
            >
              Update dbt Project
            </Button>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <h3 className="text-sm font-medium w-24">View</h3>
        <div className="flex space-x-4">
          <Button
            href={getBigQueryUrl()}
            disabled={isLoading || !getBigQueryUrl()}
            variant="primary"
            openInNewTab
            isOutline
          >
            View in BigQuery
          </Button>

          <Button
            href="https://lookerstudio.google.com/datasources/create?connectorId=AKfycbwtQR9revXcgO-AOydiUGPlhnsEmaWaarPJEDrCVIlaYt4AGlJ9DvmMnNPSDQk7Z-wtYA"
            variant="primary"
            openInNewTab
            disabled
            isOutline
          >
            Looker Studio Connector
          </Button>

          <Button href="#" variant="primary" openInNewTab disabled isOutline>
            Report Templates
          </Button>
        </div>
      </div>
    </div>
  );
};

PipelineActionStrip.propTypes = {
  pipelineId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

PipelineActionStrip.defaultProps = {
  isDisabled: false,
  className: '',
};

export default PipelineActionStrip;
