import { useMemo } from 'react';

import useDynamicFieldUpdates from 'hooks/forms/useDynamicFieldUpdates';

// This is the important unique part!
import {
  conditionColPath,
  conditionColTypePath,
} from '../FormPageSessionProperties/components/SourceGA4PathsSessionPropertyConstantsNew';

/**
 * Hook to manage dynamic field updates for GA4 BigQuery forms
 * @param {Object} tableSchema - The table schema for the BigQuery table
 * @returns {Object} An object containing the postOnChange function
 */
const useGA4BigQueryDynamicFields = (tableSchema) => {
  const dynamicFieldsSetterConfig = useMemo(
    () => [
      // When someone sets the column for a condition we need to set the type for that column.
      {
        sourcePath: conditionColPath,
        targetPath: conditionColTypePath,
        updateFunction: (sourceValue, formValues, tableSchema) => {
          const field = tableSchema?.find((f) => f.name === sourceValue);
          return field ? field.type : null;
        },
      },
      // Add more configurations as needed
    ],
    [tableSchema]
  );

  const dynamicFieldUpdates = useDynamicFieldUpdates(dynamicFieldsSetterConfig, tableSchema);

  const postOnChange = useMemo(
    () => (formValues, changedFields, changedPaths) => {
      const updates = dynamicFieldUpdates(formValues, changedFields, changedPaths);
      return updates;
    },
    [dynamicFieldUpdates]
  );

  return { postOnChange };
};

export default useGA4BigQueryDynamicFields;
