import PropTypes from 'prop-types';

import { parseDescriptionForDrawerButtonOrLink } from 'utils/componentUtils';
import { unformattedToTitleCase } from 'utils/index';

const FormInput = ({
  fieldKey,
  label,
  register,
  errors,
  type,
  showLabel = true,
  showDescription = true,
  placeholder = '',
  disabled = false,
  description,
  setValue, // Add this line
}) => {
  const handleInputChange = (event) => {
    const { value } = event.target;
    // Handle empty string case
    if (value === '') {
      event.target.value = null;
      return event;
    }
    const parsedValue = type === 'number' ? parseFloat(value) : value;
    // Handle NaN case
    if (type === 'number' && Number.isNaN(parsedValue)) {
      event.target.value = null;
      return event;
    }
    event.target.value = parsedValue;
    return event;
  };

  const errorMessage = errors?.message || (typeof errors === 'string' ? errors : null);

  return (
    <div className="sm:col-span-4">
      <div>
        {showLabel && (
          <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
            {unformattedToTitleCase(label)}
          </label>
        )}
        {showDescription &&
          description &&
          (typeof description === 'string' ? (
            <p className="mt-1 pb-0 text-sm text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </p>
          ) : (
            <div className="mt-1 pb-0 text-sm text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </div>
          ))}
      </div>
      <div className="mt-2 control">
        <input
          type={
            type === 'number'
              ? 'number'
              : type === 'password'
              ? 'password'
              : fieldKey === 'email'
              ? 'email'
              : 'text'
          }
          name={fieldKey}
          id={fieldKey}
          className={`block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            disabled ? 'bg-gray-100 cursor-not-allowed' : ''
          }`}
          placeholder={placeholder || (fieldKey === 'email' ? 'you@example.com' : '')}
          disabled={disabled}
          {...register(fieldKey, {
            onChange: handleInputChange,
            valueAsNumber: type === 'number',
          })}
        />
      </div>
      {errorMessage && (
        <div className="rounded-md bg-red-50 p-2 mt-2 pb-0">
          <p className="text-sm text-red-700" id={`${fieldKey}-description`}>
            {errorMessage}
          </p>
        </div>
      )}
    </div>
  );
};

FormInput.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  showLabel: PropTypes.bool,
  showDescription: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setValue: PropTypes.func,
};

FormInput.defaultProps = {
  type: 'text',
  showLabel: true,
  showDescription: true,
  placeholder: '',
  disabled: false,
  description: '',
  setValue: () => {},
  errors: null,
  label: '',
};

export default FormInput;
