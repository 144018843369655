import { initializePaddle } from '@paddle/paddle-js';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PADDLE_CONFIG } from './paddleConstants';

const truncatePrice = (price) => {
  if (price.endsWith('.00')) {
    return price.slice(0, -3);
  }
  return price;
};

export function usePaddlePrices(initialTiers) {
  // Set-up our states
  const [paddle, setPaddle] = useState(null);
  const [tiers, setTiers] = useState(null);
  const [billingCycle, setBillingCycle] = useState('month');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPricesFetched, setIsPricesFetched] = useState(false);

  // Use refs to store the latest values without causing re-renders
  const billingCycleRef = useRef(billingCycle);
  const tiersRef = useRef(tiers);

  // Update refs when state changes
  useEffect(() => {
    billingCycleRef.current = billingCycle;
    tiersRef.current = tiers;
  }, [billingCycle, tiers]);

  // Kick off Paddle initialization
  useEffect(() => {
    initializePaddle({
      environment: PADDLE_CONFIG.ENVIRONMENT,
      token: PADDLE_CONFIG.CLIENT_TOKEN,
    })
      .then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        } else {
          setError('Failed to initialize Paddle');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(`Failed to initialize Paddle: ${err.message}`);
        setIsLoading(false);
      });
  }, []);

  const fetchPrices = useCallback(async () => {
    if (!paddle) return;

    setIsLoading(true);
    setError(null);

    try {
      // Get our config for fetching the products
      const currentTiers = tiersRef.current;

      // Go and get all of the prices for both billing cycles
      const updatedTiers = await Promise.all(
        currentTiers.map(async (tier) => {
          const priceDisplay = {};
          const priceNumeric = {};
          const cycles = ['month', 'year'];

          await Promise.all(
            cycles.map(async (cycle) => {
              if (tier.priceId && tier.priceId[cycle]) {
                try {
                  const request = {
                    items: [{ priceId: tier.priceId[cycle], quantity: 1 }],
                  };
                  const result = await paddle.PricePreview(request);
                  const item = result.data.details.lineItems[0];

                  priceDisplay[cycle] = truncatePrice(item?.formattedUnitTotals?.total);
                  priceNumeric[cycle] = item?.unitTotals?.total || 0;
                } catch (error) {
                  console.error(`Error fetching price for ${tier.name}:`, error);
                  priceDisplay[cycle] = 'Price unavailable';
                  priceNumeric[cycle] = 0;
                }
              } else {
                priceDisplay[cycle] = 'Contact Us';
                priceNumeric[cycle] = 0;
              }
            })
          );

          return {
            ...tier,
            priceDisplay,
            priceNumeric,
          };
        })
      );

      // Update the prices
      setTiers(updatedTiers);
      setIsPricesFetched(true);
    } catch (err) {
      setError(`Failed to fetch prices: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [paddle]);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced version of fetchPrices
  const debouncedFetchPrices = useCallback(debounce(fetchPrices, 300), [fetchPrices]);

  useEffect(() => {
    if (paddle) {
      setTiers(initialTiers);
      debouncedFetchPrices();
    }
  }, [paddle, debouncedFetchPrices, initialTiers]);

  return {
    tiers,
    paddle,
    billingCycle,
    setBillingCycle,
    error,
    isLoading: isLoading || !isPricesFetched,
  };
}
