import FieldExampleValueHelper from 'components/FieldExampleValueHelper/FieldExampleValueHelper';

import SourceGA4BigQueryFormSpamFilterSQL from './SourceGA4BigQueryFormSpamFilterSQL';
/**
 * SourceGA4BigQueryFormChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {string} props.pipelineId - ID of the associated pipeline
 */
function SourceGA4BigQueryFormSpamFilter({ id, pipelineId }) {
  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading data</div>;

  return (
    <div className="source-ga4-bigquery-spam-filter">
      <SourceGA4BigQueryFormSpamFilterSQL id={id} pipelineId={pipelineId} />
      <FieldExampleValueHelper fieldName="event_name" />
    </div>
  );
}

export default SourceGA4BigQueryFormSpamFilter;
