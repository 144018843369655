import { z } from 'zod';

import { formLogicBlockSchema } from '../../_schema/sourceGA4PropertiesFormBase';

/**
 * Value schema for the property conditions for user properties.
 */
export const userPropertyValueSchema = z
  .object({
    col: z.string().nullable().optional(),
    col_type: z.string().optional().nullable().describe({ hidden: true }),
    agg: z.enum(['sum', 'count', 'distinct_count', 'max', 'last_value', 'first_value']),
    on_condition_true: z.string().optional(),
    window: z.union([z.number(), z.nan(), z.null()]).optional(),
  })
  .refine(
    (data) => {
      console.log('Refining valueSchema:', data);
      return (data.col && !data.on_condition_true) || (!data.col && data.on_condition_true);
    },
    {
      message: 'You need to either pick a column or a static value.',
      path: ['on_condition_true'],
    }
  );

/**
 * User Properties schema segments.
 */
export const userPropertyConditionBoolsSchema = z.array(
  z.object({
    conditionBlock: formLogicBlockSchema.optional(),
    value: userPropertyValueSchema,
    compare: z
      .object({
        operator: z.enum(['=', '!=', '>', '<', '>=', '<=']).nullable().optional().describe({
          '=': 'Equals',
          '!=': 'Not equals',
          '>': 'Greater than',
          '<': 'Less than',
          '>=': 'Greater than or equal to',
          '<=': 'Less than or equal to',
        }),
        value: z.union([z.string(), z.number()]).optional(),
      })
      .refine(
        (data) => {
          if (!data) return true;
          return (data.operator && data.value) || (!data.operator && !data.value);
        },
        {
          message: 'Both operator and value must be provided together',
          path: ['operator'],
        }
      )
      .optional(),
  })
);

/**
 * User Properties schema segments. - String/Number
 */
export const userPropertyConditionStringNumberSchema = z
  .array(
    z.object({
      conditionBlock: formLogicBlockSchema.optional(),
      value: userPropertyValueSchema,
    })
  )
  .min(1)
  .max(1);

/**
 * User Properties schema segments.
 */
const propertyConditionBlockStringNumberSchema = z.object({
  name: z.string(),

  propertyConditionBlock: z.object({
    propertyConditions: userPropertyConditionStringNumberSchema,
  }),
  when_to_update_property: z.object({
    ignore_null: z.boolean(),
    values_to_ignore: z.array(z.string().nullable().optional()).nullable().optional(),
  }),
  schema_type: z.enum(['bool', 'str', 'int', 'float']).optional(),
});

const propertyConditionBlockBoolsSchema = z.object({
  name: z.string(),
  propertyConditionBlock: z.object({
    andOr: z.enum(['and', 'or']).optional(),
    propertyConditions: userPropertyConditionBoolsSchema,
  }),

  when_to_update_property: z.object({
    ignore_null: z.boolean(),
    values_to_ignore: z
      .array(
        z
          .boolean()
          .describe({
            true: 'True',
            false: 'False',
          })
          .nullable()
          .optional()
      )
      .nullable()
      .optional(),
  }),
  schema_type: z.enum(['bool', 'str', 'int', 'float']).optional(),
});

/**
 * DEFINTIION: Session Properties that are numbers/strings
 */
export const sourceGA4BigQueryUserPropertiesNumberSchemaForm =
  propertyConditionBlockStringNumberSchema;

/**
 * DEFINTIION: Session Properties that are booleans
 */
export const sourceGA4BigQueryUserPropertiesBoolsSchemaForm = propertyConditionBlockBoolsSchema;

/**
 * Field info for the session properties form
 */
export const fieldInfoSourceGA4DBTUserProperties = {
  schema_type: {
    name: 'Property Type',
    description: 'This will be automatically set, but you can override this for edge cases.',
  },
  when_to_update_property: {
    name: 'When do we change the user property?',
    description:
      "You don't always want to change a user property. If someone is a customer and they don't buy this session, you still want to keep them a customer. We can control this here.",
    showLabel: false,
    showDescription: false,
    fields: {
      ignore_null: {
        name: 'Do we ignore nulls?',
        description:
          'If a user arrives and for that session their user property is null should we keep the existing value?',
      },
      values_to_ignore: {
        name: 'Values to Ignore',
        showLabel: false,
        showDescription: false,
        showArrayDescription: true,
        showArrayLabel: true,
        description:
          'Add any values which should be ignored when setting the user property for the session.',
      },
    },
  },

  // User Properties
  propertyConditionBlock: {
    name: 'Time to set-up your user property',
    showLabel: false,
    showDescription: false,
    showMasterLabel: true,
    showMasterDescription: true,
    description:
      'You can have one property block, or multiple ones which will all be evaluated to set true.',
    fields: {
      propertyConditions: {
        name: '1. Setup conditions for your user property.',
        showLabel: true,
        showArrayLabel: false,
        fields: {
          conditionBlock: {
            name: 'When these conditions are true.',
            showLabel: false,
            fields: {
              andOr: {
                name: 'And/Or',
                description:
                  'Choose whether all conditions should match (AND) or any condition should match (OR)',
              },
              conditions: {
                name: 'Conditions',
                showLabel: false,
                fields: {
                  col: {
                    name: 'Column',
                  },
                  operator: {
                    name: 'Operator',
                  },
                  value: {
                    name: 'Value',
                  },
                },
              },
            },
          },
          value: {
            name: '2. When the conditions are true, this will be the value of your user property.',
            description: 'The value to set when the condition is true',
            fields: {
              col: {
                name: 'Pick a column',
              },
              on_condition_true: {
                name: 'Static Value',
                // description: 'The value to set when the condition is true',
              },
              agg: {
                name: 'Aggregation',
                // description: 'The aggregation method to use',
              },
              window: {
                name: 'Window',
                description: 'How many days should be lookback when calculating our property.',
              },
            },
          },
          compare: {
            name: '3. (Optional) Compare to another value to get true/false.',
            description: 'Compare the value to the value below',
            fields: {
              operator: {
                name: 'Operator',
              },
            },
          },
        },
      },
    },
  },
};
