import { zodResolver } from '@hookform/resolvers/zod';
import { createContext, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getDefaultValue } from 'components/Forms/formUtils';
import { pipelineSchemaForm } from 'pages/models/pipelines/schema/pipelineSchema';

const PipelineFormContext = createContext(null);

export const PipelineFormStateProvider = ({ children }) => {
  const initialValues = {
    timezone: '+00:00',
    run_hour: 6,
  };
  // TODO fix this function.
  // Extract default values from the schema
  const schemaDefaults = useMemo(() => getDefaultValue(pipelineSchemaForm), []);

  const methods = useForm({
    resolver: zodResolver(pipelineSchemaForm),
    mode: 'onBlur',
    defaultValues: schemaDefaults,
  });

  return (
    <PipelineFormContext.Provider value={methods}>
      <FormProvider {...methods}>{children}</FormProvider>
    </PipelineFormContext.Provider>
  );
};

export const usePipelineFormState = () => {
  const context = useContext(PipelineFormContext);
  if (!context) {
    throw new Error('usePipelineFormState must be used within a PipelineFormStateProvider');
  }
  return context;
};
