import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';
import SourceGA4BigQueryPageDescription from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryPageDescription';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormAdvancedBQ from 'pages/models/source_ga4_bigquery/FormPageAdvancedBQ/components/SourceGA4BigQueryFormAdvancedBQ';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryAdvancedBQFormPage = () => {
  const { id, pipelineId } = useParams();
  const is_update = Boolean(id);
  const page_title = is_update ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline';

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SourceGA4BigQueryTitle
            title={page_title}
            pipelineId={pipelineId}
            sourceGA4BigQueryID={id}
            showActions={is_update}
          />
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={is_update} activeTab="advancedBQ" />
            <div className="flex-grow mt-5">
              <SourceGA4BigQueryPageDescription pageKey="advanced" />
              <SourceGA4BigQueryFormSection>
                <SourceGA4BigQueryFormAdvancedBQ entityId={id} />
              </SourceGA4BigQueryFormSection>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryAdvancedBQFormPage;
