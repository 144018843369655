import React from 'react';

import DrawerButton from 'components/DrawerButton/DrawerButton';

/**
 * Parses a description string and returns an array of React elements
 * @param {string} description - The description string to parse
 * @returns {Array} An array of React elements
 */
export const parseDescriptionForDrawerButtonOrLink = (description) => {
  if (!description) return null;

  if (React.isValidElement(description)) {
    // If part is a React component, return it as is
    return description;
  }

  const parts = description.split(/(\{\{button:[^}]+\}\})/);
  return parts.map((part, index) => {
    const match = part.match(/\{\{button:([^:]+):([^}]+)\}\}/);
    if (match) {
      const [, buttonText, action] = match;
      return <DrawerButton key={index} text={buttonText} action={action} />;
    }
    return part;
  });
};

/**
 * Replaces placeholders in strings by appending replacement values.
 * @param {*} data - The data structure to process.
 * @param {Object} replacements - Object containing key-value pairs for replacements.
 * @returns {*} - The processed data structure.
 */
export function replaceArgumentPlaceholdersInDrawerButton(data, replacements) {
  /**
   * Replaces placeholders in strings by appending replacement values.
   * @param {*} data - The data structure to process.
   * @param {Object} replacements - Object containing key-value pairs for replacements.
   * @returns {*} - The processed data structure.
   */
  if (typeof data === 'string') {
    return data.replace(/{{.*?@(\w+).*?}}/g, (match, key) => {
      const replacement = replacements[key];
      return replacement !== undefined ? `${match}=${replacement}` : match;
    });
  }
  if (Array.isArray(data)) {
    return data.map((item) => replaceArgumentPlaceholdersInDrawerButton(item, replacements));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        replaceArgumentPlaceholdersInDrawerButton(value, replacements),
      ])
    );
  }
  return data;
}
