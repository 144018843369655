import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import Button from 'components/Button/Button';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useFormStateEcommerce } from 'pages/models/source_ga4_bigquery/FormPageEcommerce/components/SourceGA4BigQueryFormStateProvidersEcommerce';
import { useGetGA4EventSamplingQuery } from 'services/rtkApi';

const guessDataType = (value) => {
  if (typeof value === 'number') {
    return Number.isInteger(value) ? 'integer_value' : 'float_value';
  }
  if (typeof value === 'boolean') {
    return 'boolean_value';
  }
  // You might want to add more sophisticated checks for dates, etc.
  return 'string_value';
};

const SourceGA4EventSample = ({ property_id, project_id, propertyOptions, onPropertyChange }) => {
  const methods = useFormStateEcommerce();

  // Use useWatch to track changes in form values
  const formValues =
    useWatch({
      control: methods.control,
      name: 'event_parameters_to_unnest', // Specify the field to watch
    }) || []; // Default to an empty array if undefined

  // Extract event names to filter out
  const eventNamesToUnnest = useMemo(() => {
    return formValues.map((param) => param.name);
  }, [formValues]);

  const { data, error, isLoading } = useGetGA4EventSamplingQuery(
    { property_id, project_id, query_type: 'get_sample_item_properties' },
    { skip: !property_id || !project_id }
  );

  // Filter data based on event names
  const filteredData = useMemo(() => {
    if (!data?.data) return [];
    return data.data.filter((event) => !eventNamesToUnnest.includes(event.key));
  }, [data, eventNamesToUnnest]);

  // Above this component is a FormStateProvider.
  // This means we can use methods provided from form state to alter values in the
  // form state provider.
  const handleAddParameter = useCallback(
    (parameter) => {
      const valueType = guessDataType(parameter.value_cast_str);
      const currentFormValues = methods.getValues();
      const currentEventsToUnnest = Array.isArray(currentFormValues.item_properties_to_unnest)
        ? currentFormValues.item_properties_to_unnest
        : [];

      methods.setValue('item_properties_to_unnest', [
        ...currentEventsToUnnest,
        {
          event_parameter: parameter.key,
          name: parameter.key,
          value_type: valueType,
          force_to_str: false,
        },
      ]);
    },
    [methods]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Event Name',
        accessor: 'event_name',
        filter: { enabled: true, enum: true },
      },
      {
        Header: 'Parameter',
        accessor: 'key',
        filter: { enabled: true, enum: false },
      },
      {
        Header: 'Value',
        accessor: 'value_cast_str',
        Cell: ({ value }) => <span className="font-mono text-sm">{value}</span>,
      },
      {
        Header: 'Data Type',
        accessor: 'data_type',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs ${getDataTypeColor(value)}`}>
            {value}
          </span>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        Cell: ({ row }) => (
          <Button
            onClick={() => handleAddParameter(row.original)}
            variant="primary"
            size="sm"
            isOutline
          >
            Add
          </Button>
        ),
      },
    ],
    [handleAddParameter]
  );

  const getDataTypeColor = (dataType) => {
    const colors = {
      string: 'bg-blue-100 text-blue-800',
      integer: 'bg-green-100 text-green-800',
      float: 'bg-yellow-100 text-yellow-800',
      double: 'bg-purple-100 text-purple-800',
      unknown: 'bg-gray-100 text-gray-800',
    };
    return colors[dataType] || colors.unknown;
  };

  if (!property_id || !project_id) {
    return (
      <div className="text-gray-600">
        Please select both a Property ID and a Project ID to view event sampling data.
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSpinner text="Getting event sample" size="16" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="mt-4">
      <FormSelect
        fieldKey="property_id"
        label="Property ID"
        control={methods.control}
        errors={{}}
        options={propertyOptions}
        onChange={(value) => onPropertyChange(value)}
        className="mb-4"
      />
      <FlexListTable
        columns={columns}
        data={filteredData} // Use filtered data here
        initialSort={[{ id: 'event_name', desc: false }]}
      />
    </div>
  );
};

SourceGA4EventSample.propTypes = {
  property_id: PropTypes.string,
  project_id: PropTypes.string,
  propertyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onPropertyChange: PropTypes.func.isRequired,
};

export default SourceGA4EventSample;
