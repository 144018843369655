import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';
import FormInput from 'components/Forms/FormInput/FormInput';
import SignedOutContainer from 'components/SignedOutContainer/SignedOutContainer';
import { LOGIN } from 'routes/constants';
import { forgotPassword } from 'services/auth/auth';
import LoggedOutLayout from 'templates/LoggedOutLayout';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [serverErrors, setServerErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const onSubmit = async (data) => {
    try {
      setServerErrors([]);
      await forgotPassword(data.email);
      setSuccessMessage('Password reset link has been sent to your email.');
    } catch (error) {
      setServerErrors(error.response.data.errors || ['An error occurred. Please try again.']);
    }
  };

  return (
    <LoggedOutLayout>
      <SignedOutContainer>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6"
        >
          <FormInput
            fieldKey="email"
            fieldName="email"
            register={register}
            errors={errors}
            placeholder="Enter your email"
            unformattedToTitleCase={(str) => str.charAt(0).toUpperCase() + str.slice(1)} // Simple unformattedToTitleCase function
          />
          {serverErrors.length > 0 && (
            <div className="bg-red-100 border border-red-400 text-red-700 rounded p-4">
              {serverErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 rounded p-4">
              <p>{successMessage}</p>
            </div>
          )}
          <Button type="submit" variant="primary" className="w-full">
            Reset Password
          </Button>
          <div className="mt-6 text-center">
            <Link
              to={LOGIN}
              className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900"
            >
              Remembered your password? Login
            </Link>
          </div>
        </form>
      </SignedOutContainer>
    </LoggedOutLayout>
  );
};

export default ForgotPassword;
