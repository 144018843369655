import PropTypes from 'prop-types';

import { unformattedToTitleCase } from 'utils';
import { parseDescriptionForDrawerButtonOrLink } from 'utils/componentUtils';

/**
 * FormCheckbox component for rendering a single checkbox input with label and description.
 * @param {Object} props - Component props
 * @param {string} props.fieldKey - Unique identifier for the checkbox
 * @param {string} props.label - Label text for the checkbox
 * @param {Function} props.register - React Hook Form register function
 * @param {Object} props.errors - Form errors object
 * @param {string} [props.description=''] - Optional description for the checkbox
 * @param {boolean} [props.disabled=false] - Whether the checkbox is disabled
 */
const FormCheckbox = ({
  fieldKey,
  label,
  register,
  errors = null,
  description = '',
  disabled = false,
}) => {
  const errorMessage = errors?.message || (typeof errors === 'string' ? errors : null);

  return (
    <div className="sm:col-span-4">
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id={fieldKey}
            name={fieldKey}
            type="checkbox"
            className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
              disabled ? 'bg-gray-100 cursor-not-allowed' : ''
            }`}
            disabled={disabled}
            {...register(fieldKey, {
              setValueAs: (v) => v === true,
            })}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor={fieldKey} className="font-medium text-gray-900">
            {unformattedToTitleCase(label)}
          </label>
          {description && (
            <p id={`${fieldKey}-description`} className="text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </p>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className="rounded-md bg-red-50 p-2 mt-2">
          <p className="text-sm text-red-700" id={`${fieldKey}-error`}>
            {errorMessage}
          </p>
        </div>
      )}
    </div>
  );
};

FormCheckbox.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormCheckbox;
