import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useState } from 'react';

import Alert from 'components/Alert/Alert';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { usePaddlePrices } from 'hooks/paddle/usePaddlePrices';
import { useGetCurrentUserQuery } from 'services/rtkApi';

export default function PricingTable({ initialTiers, sections }) {
  const { tiers, billingCycle, setBillingCycle, error, isLoading, paddle } =
    usePaddlePrices(initialTiers);
  const [localTiers, setLocalTiers] = useState([]);
  const { data: currentUser, error: userError, isLoading: userLoading } = useGetCurrentUserQuery();

  useEffect(() => {
    if (tiers) {
      setLocalTiers(tiers);
    }
  }, [tiers]);

  // Render loading spinner if data is still loading or tiers are not available
  if (isLoading || !tiers) {
    return (
      <div className=" mt-4">
        <LoadingSpinner text="Loading plans" isFullWidth />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        status="error"
        message="Failed to get account data so can't load pricing table. Please contact support."
      />
    );
  }

  if (userError) {
    return (
      <Alert
        status="error"
        message="Failed to get account data so can't load pricing table. Please contact support."
      />
    );
  }

  if (userLoading) {
    return <div>Loading...</div>;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function getBillingCycleText(billingCycle, priceDisplay) {
    if (priceDisplay === 'Contact Us' || priceDisplay === 'Price unavailable') return '';
    return billingCycle === 'month' ? '/month' : '/year';
  }

  function getTextSizeClass(priceDisplay, isError) {
    if (priceDisplay === 'Contact Us' || isError) return 'text-2xl';
    return 'text-4xl';
  }

  function handleCheckout(tier, billingCycle) {
    if (!paddle || !currentUser?.account) {
      console.error('Paddle not initialized or account missing');
      return;
    }

    paddle.Checkout.open({
      items: [
        {
          priceId: tier.priceId[billingCycle],
          quantity: 1,
        },
      ],
      customData: {
        account_id: currentUser.account,
      },
    });
  }

  return (
    <div className="">
      {/* Pricing customisation */}
      <div className="flex justify-center mb-12">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="relative mt-6 flex self-center rounded-lg bg-gray-100 p-0.5 sm:mt-8">
              <button
                type="button"
                className={`relative w-1/2 whitespace-nowrap rounded-md py-2 text-sm font-medium text-gray-900 focus:z-10 focus:outline-none sm:w-auto sm:px-8 ${
                  billingCycle === 'month' ? 'bg-white shadow-sm' : ''
                }`}
                onClick={() => setBillingCycle('month')}
              >
                Monthly billing
              </button>
              <button
                type="button"
                className={`relative ml-0.5 w-1/2 whitespace-nowrap rounded-md py-2 text-sm font-medium text-gray-900 focus:z-10 focus:outline-none sm:w-auto sm:px-8 ${
                  billingCycle === 'year' ? 'bg-white shadow-sm' : ''
                }`}
                onClick={() => setBillingCycle('year')}
              >
                Yearly billing
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl lg:p-6 bg-white shadow sm:rounded-lg ">
        {/* xs to lg */}
        <div className="mx-auto max-w-md space-y-8 lg:hidden">
          {localTiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                'p-6'
              )}
            >
              <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900 h-[50px] ">
                <span
                  className={`${getTextSizeClass(
                    tier.priceDisplay[billingCycle],
                    tier.error
                  )} font-bold `}
                >
                  {isLoading ? (
                    <span className="spinner" />
                  ) : tier.error ? (
                    'Price unavailable'
                  ) : (
                    tier.priceDisplay[billingCycle]
                  )}
                </span>
                {!tier.error && (
                  <span className="text-sm font-semibold">
                    {getBillingCycleText(billingCycle, tier.priceDisplay[billingCycle])}
                  </span>
                )}
              </p>
              <button
                onClick={() => handleCheckout(tier, billingCycle)}
                type="button"
                className={classNames(
                  tier.mostPopular
                    ? 'bg-red-damask-600 text-white hover:bg-red-damask-500'
                    : 'text-red-damask-600 ring-1 ring-inset ring-red-damask-200 hover:ring-red-damask-300',
                  'mt-8 w-full block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-damask-600'
                )}
              >
                Buy plan
              </button>
              <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-red-damask-600"
                            />
                            <span>
                              {feature.name}{' '}
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <span className="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate hidden lg:block">
          <div className="relative -mx-8">
            {localTiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  style={{
                    marginLeft: `${(localTiers.findIndex((tier) => tier.mostPopular) + 1) * 25}%`,
                  }}
                  aria-hidden="true"
                  className="flex w-1/4 px-4"
                >
                  <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {localTiers.map((tier) => (
                    <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                      <div className="text-sm font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {localTiers.map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline gap-x-1 text-gray-900 h-[50px] ">
                        <span
                          className={`${getTextSizeClass(
                            tier.priceDisplay[billingCycle],
                            tier.error
                          )} font-bold `}
                        >
                          {isLoading ? (
                            <div className="spinner" />
                          ) : (
                            tier.priceDisplay[billingCycle]
                          )}
                        </span>
                        {!tier.error && (
                          <span className="text-sm font-semibold leading-6">
                            {getBillingCycleText(billingCycle, tier.priceDisplay[billingCycle])}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleCheckout(tier, billingCycle)}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-red-damask-600 text-white hover:bg-red-damask-500'
                            : 'text-red-damask-600 ring-1 ring-inset ring-red-damask-200 hover:ring-red-damask-300',
                          'mt-8 w-full block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-damask-600'
                        )}
                      >
                        Buy plan
                      </button>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? 'pt-8' : 'pt-16',
                          'pb-4 text-sm font-semibold leading-6 text-gray-900'
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {localTiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === 'string' ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    aria-hidden="true"
                                    className="mx-auto h-5 w-5 text-red-damask-600"
                                  />
                                ) : (
                                  <MinusIcon
                                    aria-hidden="true"
                                    className="mx-auto h-5 w-5 text-gray-400"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'}{' '}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
