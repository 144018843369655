// In some page component

import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';
import SourceGA4BigQueryPageDescription from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryPageDescription';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormConversionsNew from 'pages/models/source_ga4_bigquery/FormPageConversionsNew/components/SourceGA4BigQueryFormAllConversions';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryFormPageConversionsNew = () => {
  const { id, pipelineId } = useParams(); // Get entityId from URL params
  const isUpdate = Boolean(id); // Determine if it's update mode
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline'; // Set page title based on mode

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SourceGA4BigQueryTitle
            title={pageTitle}
            pipelineId={pipelineId}
            sourceGA4BigQueryID={id}
            showActions={isUpdate}
          />
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="main" />
            <div className="flex-grow mt-5">
              <SourceGA4BigQueryPageDescription pageKey="conversions" />
              <SourceGA4BigQueryFormSection>
                <SourceGA4BigQueryFormConversionsNew id={id} />
              </SourceGA4BigQueryFormSection>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPageConversionsNew;
