import { ChevronRightIcon, XCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';

// eslint-disable-next-line react/prop-types
const ClickableListItem = ({ item, index, onItemClick, onItemDelete }) => (
  <li className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
    <div
      className="flex min-w-0 gap-x-4 cursor-pointer flex-grow"
      onClick={() => onItemClick(item)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onItemClick(item);
        }
      }}
    >
      <ItemContent item={item} />
    </div>
    <div className="flex shrink-0 items-center gap-x-4">
      {onItemDelete && (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onItemDelete(index);
          }}
          className="text-gray-400 hover:text-gray-500"
          aria-label="Delete item"
        >
          <XCircleIcon className="h-5 w-5" />
        </button>
      )}
      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" />
    </div>
  </li>
);

// eslint-disable-next-line react/prop-types
const ButtonListItem = ({ item, index, buttons, buttonContext }) => {
  return (
    <li className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6">
      <ItemContent item={item} />
      <div className="flex shrink-0 items-center gap-x-2">
        {buttons.map((buttonConfig, btnIndex) => {
          return (
            <Button
              key={btnIndex}
              {...buttonConfig}
              // This has to come after button config.
              onClick={(e) => {
                e?.preventDefault();
                buttonConfig.onClick?.(item, buttonContext);
              }}
            >
              {typeof buttonConfig.label === 'function'
                ? buttonConfig.label(item)
                : buttonConfig.label}
            </Button>
          );
        })}
      </div>
    </li>
  );
};

// eslint-disable-next-line react/prop-types
const ItemContent = ({ item }) => (
  <div className="min-w-0 flex-auto">
    <p className="text-sm font-semibold leading-6 text-gray-900 pb-0">{item.name}</p>
    {Array.isArray(item.description) ? (
      <div className="mt-1 space-y-1">
        {item.description.map((desc, index) => (
          <p key={index} className="flex text-xs leading-5 text-gray-500 pb-0">
            {desc}
          </p>
        ))}
      </div>
    ) : (
      <p className="mt-1 flex text-xs leading-5 text-gray-500 pb-0">{item.description}</p>
    )}
  </div>
);

/**
 * ListStacked component for displaying a list of items with either clickable rows or action buttons.
 *
 * @param {Object} props
 * @param {Array} props.items - List of items to display
 * @param {function} [props.onItemClick] - Function to call when an item is clicked (mutually exclusive with buttons)
 * @param {function} [props.onItemDelete] - Optional function to call when an item is deleted
 * @param {Array} [props.buttons] - Array of button configs (mutually exclusive with onItemClick)
 * @param {Object} [props.header] - Optional header configuration
 * @param {string} [props.header.title] - Header title text
 * @param {string} [props.header.description] - Header description text
 * @param {string} [props.className] - Additional CSS classes
 * @param {Object} [props.buttonContext] - Context for button actions
 */
const ListStacked = ({
  items,
  onItemClick,
  onItemDelete,
  buttons,
  header,
  className,
  buttonContext,
}) => {
  if (buttons && onItemClick) {
    console.warn('ListStacked: Cannot use both buttons and onItemClick. Defaulting to buttons.');
  }

  const isButtonMode = !!buttons;

  return (
    <div className={`bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ${className || ''}`}>
      {header && (
        <div className="px-4 py-5 sm:px-6 border-b border-gray-100">
          <h3 className="text-base font-semibold text-gray-900">{header.title}</h3>
          {header.description && (
            <p className="mt-1 text-sm text-gray-500 pb-0">{header.description}</p>
          )}
        </div>
      )}
      <ul className="divide-y divide-gray-100">
        {items.map((item, index) =>
          isButtonMode ? (
            <ButtonListItem
              key={item.id || index}
              item={item}
              index={index}
              buttons={buttons}
              buttonContext={buttonContext}
            />
          ) : (
            <ClickableListItem
              key={item.id || index}
              item={item}
              index={index}
              onItemClick={onItemClick}
              onItemDelete={onItemDelete}
            />
          )
        )}
      </ul>
    </div>
  );
};

ListStacked.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ).isRequired,
  onItemClick: PropTypes.func,
  onItemDelete: PropTypes.func,
  buttons: PropTypes.array,
  header: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  className: PropTypes.string,
  buttonContext: PropTypes.object,
};

export default ListStacked;
