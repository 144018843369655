import { z } from 'zod';

/**
 * DEFINTIION: Attribution standalone table.
 */
export const sourceGA4BigQueryAttributionSchemaForm = z.object({
  // Do we want to run attribution models standalone?
  attribution_standalone: z.boolean().optional().default(false),

  // How many days lookback is our attribution window?
  model_attribution_window_days: z.number().optional().default(90),

  // Which metrics do we want to use for attribution?
  attribution_metrics: z.array(
    z.object({
      name: z.string(),
      attribution_type: z.enum(['generate_attributions', 'include']),
    })
  ),
});

export const fieldInfoSourceGA4DBTAttribution = {
  attribution_standalone: {
    name: 'Enabled Attribution Comparison',
    description: 'Do you want to turn on the attribution comparison table? (See documentation).',
  },
  model_attribution_window_days: {
    name: 'Attribution Lookback Window',
    description: 'How many days lookback is our attribution window in this table?',
  },
  attribution_metrics: {
    name: 'Attribution Metrics',
    description:
      'Which metrics do we want to include in the attribution comparison table? By default nothing is included. Generate attributions will show the metric under different attribution models. Include will simply include it in the table for ease of reporting.',
    showLabel: false,
    showDescription: false,
    showArrayLabel: false,
    showArrayDescription: false,
    fields: {
      name: { name: 'Field' },
    },
  },
};
