import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';

/**
 * Alert component that displays a message with different styles and icons based on status.
 * @param {Object} props - Component props
 * @param {'warning'|'error'|'success'|'default'} props.status - Alert status
 * @param {string} props.message - Alert message
 * @param {string} [props.linkText] - Optional link text
 * @param {string} [props.linkHref] - Optional link URL
 */
const Alert = ({ status = 'default', message, linkText, linkHref, className }) => {
  const statusConfig = {
    warning: {
      style: 'border-yellow-400 bg-yellow-50 text-yellow-700',
      iconColor: 'text-yellow-400',
      Icon: ExclamationTriangleIcon,
    },
    error: {
      style: 'border-red-400 bg-red-50 text-red-700',
      iconColor: 'text-red-400',
      Icon: XCircleIcon,
    },
    success: {
      style: 'border-green-400 bg-green-50 text-green-700',
      iconColor: 'text-green-400',
      Icon: CheckCircleIcon,
    },
    default: {
      style: 'border-blue-400 bg-blue-50 text-blue-700',
      iconColor: 'text-blue-400',
      Icon: InformationCircleIcon,
    },
  };
  const { style, iconColor, Icon } = statusConfig[status] || statusConfig.default;

  return (
    <div className={`border-l-4 p-4 ${style} ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon aria-hidden="true" className={`h-5 w-5 ${iconColor}`} />
        </div>
        <div className="ml-3">
          <p className="text-sm pb-0">
            {message}{' '}
            {linkText && linkHref && (
              <a
                href={linkHref}
                className={`font-medium underline hover:${style.replace('700', '600')}`}
              >
                {linkText}
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
