import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { unformattedToTitleCase } from 'utils';
import { parseDescriptionForDrawerButtonOrLink } from 'utils/componentUtils';

const FormSelect = (
  {
    fieldKey,
    label,
    control,
    errors,
    options,
    showLabel = true,
    showDescription = true,
    isMulti = false,
    description,
    isLoading,
    disabled = false,
    className,
    isCreatable = false,
  },
  ref
) => {
  const errorMessage = errors?.message || (typeof errors === 'string' ? errors : null);

  return (
    <div className={`sm:col-span-4 ${className}`}>
      <div>
        {showLabel && (
          <div className="flex items-center">
            <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
              {unformattedToTitleCase(label)}
            </label>
            {isLoading && (
              <div className="ml-2">
                <LoadingSpinner />
              </div>
            )}
          </div>
        )}
        {showDescription &&
          description &&
          (typeof description === 'string' ? (
            <p className="mt-1 pb-0 text-sm text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </p>
          ) : (
            <div className="mt-1 pb-0 text-sm text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </div>
          ))}
      </div>
      <div className="mt-2 control">
        <Controller
          control={control}
          name={fieldKey}
          render={({ field }) => {
            const SelectComponent = isCreatable ? CreatableSelect : Select;
            return (
              <SelectComponent
                ref={ref}
                {...field}
                options={options || []}
                {...(isMulti ? { isMulti: true } : {})}
                isDisabled={disabled}
                isClearable
                value={
                  isMulti
                    ? (options || []).filter((opt) =>
                        (Array.isArray(field.value) ? field.value : []).includes(opt.value)
                      )
                    : (options || []).find((opt) => opt.value === field.value) || null
                }
                onChange={(selectedOption) => {
                  if (isMulti) {
                    field.onChange(selectedOption ? selectedOption.map((opt) => opt.value) : []);
                  } else {
                    field.onChange(selectedOption ? selectedOption.value : null);
                  }
                }}
                className={`w-full ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              />
            );
          }}
        />
      </div>
      {errorMessage && (
        <p className="mt-2 pb-0 text-sm text-red-500" id={`${fieldKey}-description`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default forwardRef(FormSelect);

FormSelect.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  showLabel: PropTypes.bool,
  showDescription: PropTypes.bool,
  isMulti: PropTypes.bool,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
};
