import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormRaw from 'pages/models/source_ga4_bigquery/FormPageRaw/SourceGA4BigQueryFormRaw';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

function SourceGA4BigQueryFormRawPage() {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate ? 'Edit GA4 Pipeline (Raw JSON)' : 'Create GA4 Pipeline (Raw JSON)';

  const handleSuccess = () => {
    // Handle success (e.g., show a notification, redirect)
    console.log('Form submitted successfully');
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SourceGA4BigQueryTitle
            title={pageTitle}
            pipelineId={pipelineId}
            sourceGA4BigQueryID={id}
            showActions={isUpdate}
          />
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="raw" />
            <div className="flex-grow mt-5">
              <div className="text-gray-600 text-sm">
                <p>Directly edit the GA4 pipeline configuration as raw JSON.</p>
              </div>
              <SourceGA4BigQueryFormRaw entityId={id} onSuccess={handleSuccess} />
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
}

export default SourceGA4BigQueryFormRawPage;
