import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import AvailableValuesForm from 'components/AvailableValuesForm/AvailableValuesForm'; // Update import
import Button from 'components/Button/Button';
import SQLEditor from 'components/SQLEditor/SQLEditor';
import SubTitle from 'components/SubTitle/SubTitle'; // Import SubTitle component
import TextBubble from 'components/TextBubble/TextBubble';
import { useUtilsFormatSQLMutation } from 'services/rtkApi';

/**
 * SQLEditorForm component for managing SQL text with formatting and validation
 * @param {Object} props - Component props
 * @param {string} props.initialSQL - Initial SQL text
 * @param {string[]} props.highlightWords - List of words to highlight
 * @param {Function} props.validationMutation - RTK mutation for SQL validation
 * @param {Function} props.onSubmit - Function to handle form submission
 * @param {Function} props.mutationHook - Hook for updating SQL
 * @param {string|number} props.entityId - ID of the entity to fetch/update
 * @param {Object} props.validationParams - Additional params for validation
 * @param {string} props.subtitle - Subtitle text to display above the form
 */
function SQLEditorForm({
  initialSQL,
  highlightWords,
  validationMutation,
  onSubmit,
  mutationHook,
  entityId,
  validationParams,
  title, // Add subtitle prop
}) {
  const [sql, setSQL] = useState(initialSQL);
  const [editor, setEditor] = useState(null);
  const [errorLocation, setErrorLocation] = useState({ line: null, character: null });
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [formatSQL, { isLoading: isFormatting }] = useUtilsFormatSQLMutation();
  const [updateSQL, { isLoading: isUpdating }] = mutationHook();
  const [validateSQL, { isLoading: isValidating, data: validationData, error: validationError }] =
    validationMutation();

  const [validationErrorMessage, setValidationErrorMessage] = useState(null);
  const [copiedField, setCopiedField] = useState(null);
  const [validationSuccessMessage, setValidationSuccessMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Set the initial SQL. This will only change when you save the form and it resaves.
   */
  useEffect(() => {
    if (initialSQL !== sql) {
      setSQL(initialSQL);
    }
  }, [initialSQL]);

  /**
   * Handle the SQL change. This will clear the error location and update the SQL.
   * @param {string} newSQL - The new SQL text
   */
  const handleSQLChange = (newSQL) => {
    setSQL(newSQL);
    setErrorLocation({ line: null, character: null });
  };

  /**
   * Handle the SQL format. This will only change when you click the format SQL button.
   */
  const handleFormat = async () => {
    try {
      const result = await formatSQL(sql).unwrap();
      const formattedSQL = result.formatted_query;
      setSQL(formattedSQL);
      if (editor) {
        editor.setValue(formattedSQL);
      }
    } catch (error) {
      console.error('Error formatting SQL:', error);
    }
  };

  /**
   * Validate the SQL content and set the validation error message if any.
   */
  const validateSQLContent = useCallback(async () => {
    try {
      const result = await validateSQL({
        ...validationParams,
        case_statement: sql,
      }).unwrap();
      setValidationErrorMessage(null);
      setErrorLocation({ line: null, character: null });
      // Set success message
      setValidationSuccessMessage('Valid SQL');
    } catch (error) {
      console.error('Error validating SQL:', error);
      setValidationErrorMessage(error.data?.error || 'An error occurred during validation');
      setErrorLocation({
        line: error.data?.error_line || null,
        character: error.data?.error_character || null,
      });
      console.log('error', error?.data?.query);
      setValidationSuccessMessage(null);
    }
  }, [validateSQL, validationParams, sql]);

  /**
   * Handle the form submit. This will automatically run the validation and submit the SQL.
   */
  const handleFormSubmit = async () => {
    try {
      setIsSubmitting(true);
      await validateSQLContent();
      if (entityId) {
        await updateSQL({ id: entityId, sql }).unwrap();
      }
      onSubmit(sql);
    } catch (error) {
      console.error('Error in form submission:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log('highlightWords', highlightWords);
  return (
    <div>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl px-4 py-6 sm:p-8"
      >
        {title && <SubTitle text={title} />} {/* Render subtitle if provided */}
        <AvailableValuesForm
          valuesObject={Object.values(highlightWords)[0] || []} // Assuming single category
          valueType="fields"
        />
        {validationErrorMessage && (
          <div className="mb-4">
            <TextBubble color="red" text={validationErrorMessage} />
          </div>
        )}
        {validationSuccessMessage && (
          <div className="mb-4">
            <TextBubble color="green" text={validationSuccessMessage} />
          </div>
        )}
        <SQLEditor
          value={sql}
          highlightWords={highlightWords}
          onChange={handleSQLChange}
          onMount={setEditor}
          errorLine={errorLocation.line}
          errorCharacter={errorLocation.character}
        />
        {errors.sql && <span>This field is required</span>}
        <div className="mt-4">
          <Button
            onClick={validateSQLContent}
            disabled={isValidating || isFormatting || isSubmitting}
            variant="secondary"
            isOutline
            className="mr-4"
          >
            {isValidating ? 'Validating...' : 'Validate SQL'}
          </Button>
          <Button
            onClick={handleFormat}
            disabled={isValidating || isFormatting || isSubmitting}
            variant="secondary"
            isOutline
            className="mr-4"
          >
            {isFormatting ? 'Formatting...' : 'Format SQL'}
          </Button>
          <Button
            type="submit"
            variant="primary"
            style={{ marginTop: '10px' }}
            disabled={isValidating || isFormatting || isSubmitting || isUpdating}
          >
            {isUpdating || isSubmitting ? 'Updating...' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
}

SQLEditorForm.propTypes = {
  initialSQL: PropTypes.string,
  highlightWords: PropTypes.arrayOf(PropTypes.string),
  validationMutation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mutationHook: PropTypes.func.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationParams: PropTypes.object,
  title: PropTypes.string, // Add prop type for subtitle
};

SQLEditorForm.defaultProps = {
  initialSQL: '',
  highlightWords: [],
  entityId: undefined,
  validationParams: {},
  title: '', // Default subtitle to an empty string
};

export default SQLEditorForm;
