import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

import SourceGA4BigQueryFormActionButtons from './SourceGA4BigQueryFormActionButtons';
/**
 * Title component for GA4 BigQuery pages that includes back button, title and action buttons
 * @param {Object} props
 * @param {string} props.title - Page title to display
 * @param {string} props.pipelineId - ID of the pipeline
 * @param {string} props.sourceGA4BigQueryID - ID of the GA4 BigQuery source
 * @param {boolean} props.showActions - Whether to show action buttons
 */
const SourceGA4BigQueryTitle = ({
  title,
  pipelineId,
  sourceGA4BigQueryID,
  showActions = false,
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-4">
        <Link to={`/pipelines/${pipelineId}`} className="text-gray-400 hover:text-gray-600">
          <ArrowLeftIcon className="h-5 w-5" />
        </Link>
        <h1 className="text-xl font-semibold leading-7 text-gray-900">{title}</h1>
      </div>
      {showActions && (
        <SourceGA4BigQueryFormActionButtons
          pipelineId={pipelineId}
          sourceGA4BigQueryID={sourceGA4BigQueryID}
        />
      )}
    </div>
  );
};

export default SourceGA4BigQueryTitle;
