import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { accountSchemaForm } from 'pages/models/accounts/schema/accountSchema';
import {
  useAddAccountMutation,
  useGetAccountByIdQuery,
  useGetUsersQuery,
  useUpdateAccountMutation,
} from 'services/rtkApi'; // adjust the import based on your API service setup

const AccountForm = ({ onSuccess }) => {
  const { id } = useParams(); // This will get the entityId from the URL
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';
  const mutationHook = isUpdate ? useUpdateAccountMutation : useAddAccountMutation;
  const queryHook = isUpdate ? useGetAccountByIdQuery : undefined;

  // Fetching data with RTK Query for users
  const { data: usersData } = useGetUsersQuery();
  const users = usersData?.results || [];

  // Transform data for react-select
  const usersOptions = users?.map((user) => ({ value: user.id, label: user.email })) ?? [];

  // Updated foreignKeyOptions to match the new format
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['users'],
        options: usersOptions,
      },
    ],
    [usersOptions]
  );

  return (
    <CrudForm
      entityId={id}
      key={`account_${formType}`}
      foreignKeyOptions={foreignKeyOptions}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={accountSchemaForm}
      tabs={[
        { key: 'main2', tabName: 'Main', fieldList: ['name'], description: 'This is the main tab' },
        {
          key: 'users',
          tabName: 'Users',
          fieldList: ['users'],
          description: 'This is the users tab',
        },
      ]}
      onSuccess={onSuccess}
    />
  );
};

export default AccountForm;
