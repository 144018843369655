import { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import AlertList from 'components/AlertList/AlertList';
import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4BigQueryPart1,
  sourceGA4BigQueryPart1SchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageStage1/schema/sourceGA4DBTPage1Schema';
import { SOURCE_GA4_BIGQUERY_UPDATE_FORM } from 'routes/constants';
import {
  useAddSourceGA4BigQueryMutation,
  useGetPipelineByIdQuery,
  useGetSourceGA4BigQueryByIdQuery,
  useUpdateSourceGA4BigQueryMutation,
} from 'services/rtkApi';
import { hasAnyPermissionErrors } from 'utils/index';

import { useGA4BigQueryFormData2 } from '../../_hooks/useGA4BigQueryFormData2';

import { GA4FormStage1StateProvider, useGA4FormStage1State } from './GA4FormStage1StateProvider';

/**
 * This component handles the first stage of the GA4 BigQuery export form.
 * It wraps the main form content in a state provider to manage form state.
 */
const SourceGA4BigQueryFormStage1 = ({ onSuccess }) => {
  return (
    <GA4FormStage1StateProvider>
      <SourceGA4BigQueryFormContent onSuccess={onSuccess} />
    </GA4FormStage1StateProvider>
  );
};

/**
 * Main form content component that handles:
 * 1. Project ID state management and updates
 * 2. BigQuery dataset fetching and authorization
 * 3. Form field visibility based on user selections
 * 4. Form submission and navigation
 */
const SourceGA4BigQueryFormContent = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const navigate = useNavigate();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  const methods = useGA4FormStage1State();
  const { control } = methods;
  const formValues = useWatch({ control });

  const [projectId, setProjectId] = useState(null);

  // Get the pipeline which this source belongs to.
  const { data: pipelineData } = useGetPipelineByIdQuery(pipelineId);

  useEffect(() => {
    const currentValues = methods.getValues();
    const currentProjectId = currentValues.gcloud_project_id;

    if (currentProjectId !== projectId) {
      setProjectId(currentProjectId);
    }
  }, [methods, formValues]);

  // Use the new hook with project ID
  const queryParams = {
    project_id: projectId,
    ignore_ga4_raw_exports: true,
  };

  /**
   * Handles fetching and managing foreign key options and data.
   */
  const { foreignKeyOptions, loadingForeignKeys, refetchDatasets, isAuthorized, errors, datasets } =
    useGA4BigQueryFormData2(queryParams);

  /**
   * Refetches datasets when the user is authorized and the project ID changes.
   */
  useEffect(() => {
    if (isAuthorized) {
      refetchDatasets();
    }
  }, [isAuthorized, refetchDatasets]);

  /**
   * Form Setup
   */
  const mutationHook = isUpdate
    ? useUpdateSourceGA4BigQueryMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = isUpdate ? useGetSourceGA4BigQueryByIdQuery : undefined;

  const [hiddenFields, setHiddenFields] = useState([
    'run_for_period_start_date',
    'run_for_period_end_date',
  ]);

  /**
   * Manages which fields are hidden based on the run_for_period toggle.
   * When run_for_period is true, show start/end date fields.
   * When false, hide these fields.
   */
  const onFieldChange = useCallback((formValues, changedFields) => {
    if ('run_for_period' in changedFields) {
      const newValue = changedFields.run_for_period;
      setHiddenFields((prevHidden) => {
        if (newValue === true) {
          return prevHidden.filter(
            (field) => !['run_for_period_start_date', 'run_for_period_end_date'].includes(field)
          );
        }
        return [
          ...new Set([...prevHidden, 'run_for_period_start_date', 'run_for_period_end_date']),
        ];
      });
    }
  }, []);

  // If this is a create form then we want to go and get the timezone for the property.

  // When you leave each stage we need to check for unsaved changes and warn the user.

  /**
   * Handles successful form submission by navigating to the update form
   * with the newly created/updated entity ID
   */
  const handleSuccess = useCallback(
    (resultId) => {
      const updateUrl = SOURCE_GA4_BIGQUERY_UPDATE_FORM.replace(':pipelineId', pipelineId).replace(
        ':id',
        resultId
      );
      navigate(updateUrl);
    },
    [pipelineId, navigate]
  );

  /**
   * Adds the pipeline ID to the form data before submission
   */
  const preSubmit = useCallback(
    (formData) => {
      return {
        ...formData,
        pipeline: pipelineId,
      };
    },
    [pipelineId]
  );

  /**
   * Setup alerts that are outside of normal form validation.
   */
  const alerts = [];

  // Check for region mismatches between selected properties and pipeline
  const checkRegionMismatch = () => {
    const selectedPropertyIds = formValues.property_ids || [];
    if (!selectedPropertyIds.length || !datasets || !pipelineData?.gcloud_region) return false;

    // Find datasets that match selected property IDs with exact analytics_{id} pattern
    const selectedDatasets = datasets.filter((dataset) =>
      selectedPropertyIds.some((propId) => dataset.id === `analytics_${propId}`)
    );

    // Check if any selected dataset's location doesn't match pipeline region
    const mismatchedDatasets = selectedDatasets.filter(
      (dataset) => dataset.location.toLowerCase() !== pipelineData.gcloud_region.toLowerCase()
    );

    return {
      hasMismatch: mismatchedDatasets.length > 0,
      pipelineRegion: pipelineData.gcloud_region,
      mismatchedRegions: [...new Set(mismatchedDatasets.map((d) => d.location))],
      mismatchedProperties: mismatchedDatasets.map((d) => d.id.replace('analytics_', '')),
    };
  };

  const regionCheck = checkRegionMismatch();

  if (regionCheck.hasMismatch) {
    alerts.push({
      type: 'error',
      message: `Region mismatch detected: Your pipeline is in ${
        regionCheck.pipelineRegion
      } but you've selected GA4 properties (${regionCheck.mismatchedProperties.join(
        ', '
      )}) in ${regionCheck.mismatchedRegions.join(
        ', '
      )}. Properties must be in the same region as the pipeline. Please create a new pipeline in the correct region.`,
    });
  }

  if (hasAnyPermissionErrors(errors || {})) {
    alerts.push({
      message: 'You need to give your account the correct permissions in BigQuery.',
      linkText: 'View documentation',
      linkHref: 'https://www.pipedout.com/documentation/setup-bigquery-permissions',
    });
  }

  console.log('alerts', alerts);

  const submitButtonOverride =
    alerts.length > 0 ? { disabled: true, text: 'Errors found. See messages above.' } : undefined;

  return (
    <>
      <AlertList alerts={alerts} />
      <CrudForm
        entityId={id}
        key={`pipeline_${formType}`}
        methods={methods}
        formType={formType}
        mutationHook={mutationHook}
        queryHook={queryHook}
        schema={sourceGA4BigQueryPart1SchemaForm}
        foreignKeyOptions={foreignKeyOptions}
        loadingForeignKeys={loadingForeignKeys}
        fieldInfo={fieldInfoSourceGA4BigQueryPart1}
        onSuccess={handleSuccess}
        warnOnUnsavedChanges
        onFieldChange={onFieldChange}
        preSubmit={preSubmit}
        hiddenFields={hiddenFields}
        submitButtonOverride={submitButtonOverride}
      />
    </>
  );
};

export default SourceGA4BigQueryFormStage1;
