/**
 * A simple container component with right padding and optional bottom border
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child elements to render inside the container
 * @param {boolean} [props.isBottomBorder=false] - Whether to show a bottom border
 */
const SourceGA4BigQueryFormSection = ({ children, isBottomBorder = false }) => {
  return (
    <div className={`pl-10 ${isBottomBorder ? 'border-b border-gray-200 mb-8 pb-2' : ''}`}>
      {children}
    </div>
  );
};

export default SourceGA4BigQueryFormSection;
