import { useMemo } from 'react';

import {
  conditionColPath,
  propertyConditionsValueColPath,
} from '../components/SourceGA4PathsConstantsNew';

/**
 * Hook to fetch and process GA4 BigQuery schema options
 * @param {Object} pipelineData - The pipeline data containing project and dataset info
 * @returns {Object} An object containing schema data, options, and loading state
 */
const useSourceGA4BigQuerySchemaOptions = (tableSchema, isLoadingTableSchema) => {
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: conditionColPath,
        options:
          tableSchema
            ?.filter((field) => field.type !== 'RECORD' && field.type !== 'ARRAY')
            .map((field) => ({ label: field.name, value: field.name })) || [],
      },
      {
        key: propertyConditionsValueColPath,
        options:
          tableSchema
            ?.filter((field) => field.type !== 'RECORD' && field.type !== 'ARRAY')
            .map((field) => ({ label: field.name, value: field.name })) || [],
      },
    ],
    [tableSchema]
  );

  const loadingForeignKeys = useMemo(
    () => (isLoadingTableSchema ? [conditionColPath, propertyConditionsValueColPath] : []),
    [isLoadingTableSchema]
  );

  return {
    tableSchema,
    foreignKeyOptions,
    loadingForeignKeys,
    isLoadingTableSchema,
  };
};

export default useSourceGA4BigQuerySchemaOptions;
