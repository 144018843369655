import React from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { userSchemaForm } from 'pages/models/users/schema/userSchema';
import { useUpdateUserMutation, useAddUserMutation, useGetUserByIdQuery } from 'services/rtkApi'; // adjust the import based on your API service setup

const UserForm = ({ onSuccess }) => {
  const { id } = useParams(); // This will get the entityId from the URL
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';
  const mutationHook = isUpdate ? useUpdateUserMutation : useAddUserMutation;
  const queryHook = isUpdate ? useGetUserByIdQuery : undefined;

  // Additional state and handlers can be set up here as needed

  return (
    <CrudForm
      entityId={id}
      key={`User_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={userSchemaForm}
      onSuccess={onSuccess}
    />
  );
};

export default UserForm;
