import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import {
  useGetLatestPipelineRunQuery,
  useGetSourceGA4BigQueryByIdQuery,
  useRunPipelineMutation,
} from 'services/rtkApi';

/**
 * Renders buttons for updating dbt project and running pipeline, along with pipeline status.
 * @param {Object} props - Component props
 * @param {number} props.pipelineId - ID of the pipeline
 * @param {number} props.sourceGA4BigQueryID - ID of the SourceGA4BigQuery
 * @param {string} props.className - Additional CSS classes for the container
 */
function SourceGA4BigQueryFormActionButtons({ pipelineId, sourceGA4BigQueryID, className }) {
  /**
   * Running the pipeline and showing the status.
   *
   * Pipeline runs aren't identical. Because we want to autocomplete data for people.
   * The first time you run, we build it up step by step.
   */
  // Run the pipeline
  const [runPipeline, { isLoading: isRunningPipeline }] = useRunPipelineMutation();

  // Get the latest pipeline run
  const {
    data: latestPipelineRun,
    isLoading: isLoadingLatestRun,
    refetch: refetchLatestPipelineRun,
  } = useGetLatestPipelineRunQuery(pipelineId);

  // Get the source GA4 BigQuery
  const { data: sourceGA4BigQuery } = useGetSourceGA4BigQueryByIdQuery(sourceGA4BigQueryID);

  // Set the statuses.
  const [buildStatus, setBuildStatus] = useState('not_started');

  useEffect(() => {
    if (sourceGA4BigQuery) {
      setBuildStatus(sourceGA4BigQuery.first_pipeline_build_status || 'not_started');
    }
  }, [sourceGA4BigQuery]);

  /**
   * PIPELINE STATUS
   * Set the current status of the pipeline.
   * Is it running? Has it finished?
   */
  const [pipelineStatus, setPipelineStatus] = useState({ text: 'Unknown', color: 'gray' });
  useEffect(() => {
    if (latestPipelineRun === null) {
      setPipelineStatus({ text: 'No runs yet', color: 'gray' });
    } else if (latestPipelineRun) {
      if (latestPipelineRun.is_run_in_progress) {
        setPipelineStatus({ text: 'Running', color: 'blue' });
      } else {
        setPipelineStatus({
          text: latestPipelineRun.did_run_succeed ? 'Success' : 'Failed',
          color: latestPipelineRun.did_run_succeed ? 'green' : 'red',
        });
      }
    }
  }, [latestPipelineRun]);

  const isRunInProgress = latestPipelineRun?.is_run_in_progress || false;
  /**
   * RUN PIPELINE
   */
  const handleRunPipeline = async () => {
    let runType = 'ga4'; // Default run type

    // Fire the correct run type based on the build status.
    if (buildStatus === 'not_started') {
      runType = 'ga4_base_only_copy';
    } else if (buildStatus === 'raw_data_copied') {
      runType = 'ga4_base_only_events';
    } else if (buildStatus === 'base_table_built') {
      runType = 'ga4_post_base';
    }

    try {
      await runPipeline({ id: pipelineId, run_type: runType, full_refresh: true }).unwrap();
      // Refetch the latest pipeline run data
      await refetchLatestPipelineRun();
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to run pipeline:', error);
    }
  };

  const getRunButtonText = () => {
    if (isRunInProgress) {
      return 'Run in Progress';
    }
    if (buildStatus === 'not_started') {
      return 'Start Setup Run';
    }
    if (buildStatus === 'output_tables_built') {
      return 'Run GA4';
    }
    return 'Continue Setup Run';
  };

  // Define the setup steps
  const setupSteps = [
    { name: 'Raw Data Copied', href: '#' },
    { name: 'Base Table', href: '#' },
    { name: 'Intermediate Tables', href: '#' },
    { name: 'Output Tables', href: '#' },
  ];

  // Map backend status to step names
  const statusToStepName = {
    not_started: 'Not Started',
    raw_data_copied: 'Raw Data Copied',
    base_table_built: 'Base Table',
    intermediate_tables_built: 'Intermediate Tables',
    output_tables_built: 'Output Tables',
  };

  // Get the last completed step based on build status
  const lastCompletedStep = statusToStepName[buildStatus] || 'Not Started';
  const lastCompletedIndex = setupSteps.findIndex((step) => step.name === lastCompletedStep);

  // Current step is the next one after last completed
  const currentStep =
    lastCompletedIndex < setupSteps.length - 1
      ? setupSteps[lastCompletedIndex + 1].name
      : lastCompletedStep;

  // Using the existing pipeline status variables
  const isRunning = latestPipelineRun?.is_run_in_progress || false;
  const isSuccess = latestPipelineRun?.did_run_succeed || false;

  /**
   * If the pipeline is running, poll for updates every 60 seconds
   */
  useEffect(() => {
    let intervalId;

    if (isRunInProgress) {
      // Poll every 60 seconds when a run is in progress
      intervalId = setInterval(() => {
        refetchLatestPipelineRun();
      }, 60000);
    }

    // Cleanup interval on unmount or when run completes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunInProgress, refetchLatestPipelineRun]);

  return (
    <div className={`flex items-center justify-end space-x-4 ${className}`}>
      <ProgressBar
        steps={setupSteps.map((step, index) => ({
          ...step,
          isRunning: step.name === currentStep && isRunning,
          isSuccess: index <= lastCompletedIndex,
        }))}
        currentStep={currentStep}
        className="w-48"
      />
      <Button
        onClick={handleRunPipeline}
        loading={isRunningPipeline}
        disabled={isRunningPipeline || !buildStatus || isRunInProgress}
        variant="secondary"
      >
        {getRunButtonText()}
      </Button>
    </div>
  );
}

SourceGA4BigQueryFormActionButtons.propTypes = {
  pipelineId: PropTypes.string.isRequired,
  sourceGA4BigQueryID: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SourceGA4BigQueryFormActionButtons.defaultProps = {
  className: '',
};

export default SourceGA4BigQueryFormActionButtons;
