import { useDispatch, useSelector } from 'react-redux';

import FormSelectNonHookForm from 'components/FormNonReactHook/FormSelectNonHookForm';
import { setAccountOverride } from 'services/auth/authSlice';
import { useGetAccountsQuery } from 'services/rtkApi';

const TenantSwitcher = () => {
  const { data: accountsData, isLoading } = useGetAccountsQuery();
  const dispatch = useDispatch();
  const accountOverride = useSelector((state) => state.auth.accountOverride);

  const options =
    accountsData?.results?.map((account) => ({
      value: account.id,
      label: account.name,
    })) || [];

  return (
    <div className="mt-2 flex items-center gap-2 mb-2">
      <span className="whitespace-nowrap">Account Switcher:</span>
      <FormSelectNonHookForm
        fieldKey="account"
        label="Switch Account"
        showLabel={false}
        value={accountOverride}
        onChange={(value) => dispatch(setAccountOverride(value))}
        options={options}
        isLoading={isLoading}
        className="flex-1"
      />
    </div>
  );
};

export default TenantSwitcher;
