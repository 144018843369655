import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../Tooltip/Tooltip'; // Import Tooltip component

/**
 * @typedef {Object} CustomColConfig
 * @property {string[]} fields - List of field IDs to include
 * @property {number} cols - Number of columns to span
 * @property {boolean} [showLabelAbove] - Whether to show labels above values instead of beside
 * @property {string} [labelWidth] - CSS grid column width for label (e.g. '25%' or '1fr')
 */

/**
 * Displays model data in a customizable column layout with Tailwind CSS styling
 * @param {Object} props
 * @param {Object} props.data - The model data from the API
 * @param {Object} props.fieldNames - Lookup for nice field names
 * @param {number} props.numCols - Default number of columns (defaults to 2)
 * @param {string} props.labelWidth - Default label width for all columns
 * @param {CustomColConfig[]} props.dataCustomCols - Custom column configs
 * @param {Array} props.formatters - List of [fieldId, formatterFunction] pairs
 * @param {Array} props.renderers - List of [fieldId, rendererFunction(value, formattedValue)] pairs
 */
const ModelDisplay = ({
  data,
  fieldNames,
  numCols = 2,
  labelWidth = '50%',
  dataCustomCols = [],
  formatters = [],
  renderers = [],
}) => {
  const formatValue = (fieldId, value) => {
    const formatter = formatters.find(([id]) => id === fieldId)?.[1];
    return formatter ? formatter(value) : value || 'N/A';
  };

  const renderValue = (fieldId, rawValue) => {
    const formattedValue = formatValue(fieldId, rawValue);
    const renderer = renderers.find(([id]) => id === fieldId)?.[1];

    if (renderer) {
      return renderer(rawValue, formattedValue);
    }

    return (
      <Tooltip content={formattedValue} position="left">
        <span className="truncate block max-w-xs">{formattedValue}</span>
      </Tooltip>
    );
  };

  // Organize entries based on custom column configurations
  const entries = Object.entries(data);
  const customFieldsSet = new Set(dataCustomCols.flatMap((config) => config.fields));
  const defaultEntries = entries.filter(([fieldId]) => !customFieldsSet.has(fieldId));

  const getLabelValueGridStyle = (config) => {
    if (!config.labelWidth) return 'grid-cols-2'; // Default 50-50 split

    return `grid-template-columns: ${config.labelWidth} 1fr`;
  };

  return (
    <div className="space-y-8">
      {/* Default column layout */}
      {defaultEntries.length > 0 && (
        <div className={`grid grid-cols-${numCols} gap-4 mt-4`}>
          {Array.from({ length: numCols }, (_, i) => {
            const start = (defaultEntries.length / numCols) * i;
            const end = (defaultEntries.length / numCols) * (i + 1);
            const columnEntries = defaultEntries.slice(start, end);

            return (
              <div
                key={i}
                className="grid gap-4"
                style={{ gridTemplateColumns: `${labelWidth} 1fr` }}
              >
                <dl className="space-y-4">
                  {columnEntries.map(([fieldId]) => (
                    <dt key={fieldId} className="text-sm font-medium text-gray-500 min-h-[1.5rem]">
                      {fieldNames[fieldId]?.name || fieldId}
                    </dt>
                  ))}
                </dl>
                <dl className="space-y-4">
                  {columnEntries.map(([fieldId, value]) => (
                    <dd key={fieldId} className="text-sm text-gray-900 min-h-[1.5rem]">
                      {renderValue(fieldId, value)}
                    </dd>
                  ))}
                </dl>
              </div>
            );
          })}
        </div>
      )}

      {/* Custom column layouts */}
      {dataCustomCols.map((config, index) => {
        const configEntries = entries.filter(([fieldId]) => config.fields.includes(fieldId));

        if (config.showLabelAbove) {
          return (
            <div key={index} className={`grid grid-cols-${config.cols} gap-4 mt-4`}>
              {configEntries.map(([fieldId, value]) => (
                <div key={fieldId} className="space-y-2">
                  <dt className="text-sm font-medium text-gray-500">
                    {fieldNames[fieldId]?.name || fieldId}
                  </dt>
                  <dd className="text-sm text-gray-900">{renderValue(fieldId, value)}</dd>
                </div>
              ))}
            </div>
          );
        }

        return (
          <div
            key={index}
            className={`grid grid-cols-${config.cols} gap-4 mt-4`}
            style={{
              gridTemplateColumns: config.labelWidth
                ? `${config.labelWidth} 1fr`
                : `${labelWidth} 1fr`,
            }}
          >
            {Array.from({ length: config.cols }, (_, i) => {
              const start = (configEntries.length / config.cols) * i;
              const end = (configEntries.length / config.cols) * (i + 1);
              const columnEntries = configEntries.slice(start, end);

              return (
                <div
                  key={i}
                  className="grid gap-4"
                  style={{
                    gridTemplateColumns: config.labelWidth
                      ? `${config.labelWidth} 1fr`
                      : `${labelWidth} 1fr`,
                  }}
                >
                  <dl className="space-y-4">
                    {columnEntries.map(([fieldId]) => (
                      <dt
                        key={fieldId}
                        className="text-sm font-medium text-gray-500 min-h-[1.5rem]"
                      >
                        {fieldNames[fieldId]?.name || fieldId}
                      </dt>
                    ))}
                  </dl>
                  <dl className="space-y-4">
                    {columnEntries.map(([fieldId, value]) => (
                      <dd key={fieldId} className="text-sm text-gray-900 min-h-[1.5rem]">
                        {renderValue(fieldId, value)}
                      </dd>
                    ))}
                  </dl>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

ModelDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  fieldNames: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  numCols: PropTypes.number,
  labelWidth: PropTypes.string,
  dataCustomCols: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
      cols: PropTypes.number.isRequired,
      showLabelAbove: PropTypes.bool,
      labelWidth: PropTypes.string,
    })
  ),
  formatters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ),
  renderers: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ),
};

ModelDisplay.defaultProps = {
  numCols: 2,
  labelWidth: '50%',
  dataCustomCols: [],
  formatters: [],
  renderers: [],
};

export default ModelDisplay;
