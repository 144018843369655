import PropTypes from 'prop-types';
import { unformattedToTitleCase } from 'utils';
import { parseDescriptionForDrawerButtonOrLink } from 'utils/componentUtils';

const FormInputNonHookForm = ({
  fieldKey,
  label,
  value,
  onChange,
  type,
  showLabel = true,
  placeholder = '',
  disabled = false,
  description,
}) => {
  const handleInputChange = (event) => {
    const { value } = event.target;
    const parsedValue = type === 'number' ? parseFloat(value) : value;
    onChange(parsedValue);
  };

  return (
    <div className="sm:col-span-4">
      {showLabel && (
        <div>
          <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
            {unformattedToTitleCase(label)}
          </label>
          {description && (
            <p className="mt-1 pb-0 text-sm text-gray-500">
              {parseDescriptionForDrawerButtonOrLink(description)}
            </p>
          )}
        </div>
      )}
      <div className="mt-2 control">
        <input
          type={
            type === 'number'
              ? 'number'
              : type === 'password'
              ? 'password'
              : fieldKey === 'email'
              ? 'email'
              : 'text'
          }
          name={fieldKey}
          id={fieldKey}
          value={value}
          onChange={handleInputChange}
          className={`block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            disabled ? 'bg-gray-100 cursor-not-allowed' : ''
          }`}
          placeholder={placeholder || (fieldKey === 'email' ? 'you@example.com' : '')}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

FormInputNonHookForm.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.string,
};

FormInputNonHookForm.defaultProps = {
  type: 'text',
  showLabel: true,
  placeholder: '',
  disabled: false,
  description: '',
};

export default FormInputNonHookForm;
