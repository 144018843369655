import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';

// TODO: Replace these with your actual IDs from tawk.to dashboard
const TAWK_PROPERTY_ID = '6758dde8af5bfec1dbda1052';
const TAWK_WIDGET_ID = '1iepi5li9';

/**
 * TawkTo chat widget component that provides customer support functionality
 * @returns {JSX.Element} TawkTo chat widget
 */
const TawkTo = () => {
  const tawkMessengerRef = useRef();

  return (
    <TawkMessengerReact
      propertyId={TAWK_PROPERTY_ID}
      widgetId={TAWK_WIDGET_ID}
      ref={tawkMessengerRef}
    />
  );
};

export default TawkTo;
