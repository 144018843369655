// src/sagas/index.js
import { all } from 'redux-saga/effects';

import {
  getSocialRedirectUrlSaga,
  googleAPIAuthRedirectUrlSaga,
  googleAPIAuthRegisterSaga,
  loginSaga,
  logoutSaga,
  refreshTokenSaga,
  registerSaga,
  socialLoginSaga,
  watchAuthApiErrors,
  watchAuthEvents,
} from 'services/auth/authSaga';
import { watchNonAuthApiErrors } from 'services/errors/errorSaga';
import { sourceGA4BigQueryFormSaga } from 'services/sourceGA4BigQuery/sourceGA4BigQuerySaga';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    registerSaga(),
    logoutSaga(),
    socialLoginSaga(),
    refreshTokenSaga(),
    getSocialRedirectUrlSaga(),
    googleAPIAuthRegisterSaga(),
    googleAPIAuthRedirectUrlSaga(),
    watchAuthApiErrors(),
    watchNonAuthApiErrors(),
    sourceGA4BigQueryFormSaga(),
    watchAuthEvents(),
    // other sagas...
  ]);
}
