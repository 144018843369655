import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Tooltip from '../Tooltip/Tooltip';

/**
 * A progress indicator component showing current step and navigation dots
 * @param {Object} props
 * @param {Array<{name: string, href: string, isRunning: boolean, isSuccess: boolean}>} props.steps - Array of step objects
 * @param {string} props.currentStep - Name of the current step
 */
const ProgressBar = ({ steps, currentStep }) => {
  const currentStepIndex = steps.findIndex((step) => step.name === currentStep);

  const getStatus = (index) => {
    if (index === currentStepIndex) return 'current';
    if (index < currentStepIndex) return 'complete';
    return 'upcoming';
  };

  return (
    <nav
      aria-label="Progress"
      className="flex items-center justify-center border border-gray-200 rounded-lg p-4"
    >
      <span className="text-sm font-medium">
        {currentStepIndex + 1}. {currentStep}
      </span>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step, index) => (
          <li key={step.name}>
            <Tooltip
              content={`${step.name} - ${
                step.isRunning
                  ? 'In Progress'
                  : getStatus(index) === 'complete'
                  ? step.isSuccess
                    ? 'Completed Successfully'
                    : 'Failed'
                  : getStatus(index) === 'current'
                  ? 'Current Step'
                  : ''
              }`}
              position="bottom"
            >
              {step.isRunning ? (
                <LoadingSpinner size="4" className="text-indigo-600" />
              ) : getStatus(index) === 'complete' ? (
                <a
                  href={step.href}
                  className={`block h-2.5 w-2.5 rounded-full ${
                    step.isSuccess ? 'bg-green-600' : 'bg-red-600'
                  } hover:bg-opacity-75`}
                >
                  <span className="sr-only">{step.name}</span>
                </a>
              ) : getStatus(index) === 'current' ? (
                <a
                  href={step.href}
                  aria-current="step"
                  className="relative flex items-center justify-center"
                >
                  <span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
                    <span className="h-full w-full rounded-full bg-indigo-200" />
                  </span>
                  <span
                    aria-hidden="true"
                    className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              ) : (
                <a
                  href={step.href}
                  className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                >
                  <span className="sr-only">{step.name}</span>
                </a>
              )}
            </Tooltip>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressBar;

// Usage example:
// const steps = [
//   { name: 'Details', href: '#', isRunning: false, isSuccess: true },
//   { name: 'Address', href: '#', isRunning: true, isSuccess: false },
//   { name: 'Payment', href: '#', isRunning: false, isSuccess: true },
// ]
// <ProgressBar steps={steps} currentStep="Address" /> // Shows spinner for "Address"
