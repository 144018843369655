import { useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';

import SourceGA4BigQueryFormChannelGroupingSQL from './SourceGA4BigQueryFormChannelGroupingSQL';

/**
 * SourceGA4BigQueryFormChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {string} props.pipelineId - ID of the associated pipeline
 */
function SourceGA4BigQueryFormChannelGroupings({ id, pipelineId }) {
  // Use the query hook to fetch data
  const { data, error, isLoading } = useGetSourceGA4BigQueryByIdQuery(id);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading data</div>;

  const hasExtraChannelGroupings = data?.dbt_project_file?.extra_channel_groupings?.length > 0;

  return (
    <div className="source-ga4-bigquery-channel-groupings">
      {/* <SourceGA4BigQueryFormChannelGroupingToggle /> */}
      {hasExtraChannelGroupings && (
        <SourceGA4BigQueryFormChannelGroupingSQL id={id} pipelineId={pipelineId} data={data} />
      )}
    </div>
  );
}

export default SourceGA4BigQueryFormChannelGroupings;
