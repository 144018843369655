// In some page component

import Button from 'components/Button/Button';
import PipelineTable from 'pages/models/pipelines/components/PipelineTable';
import { PIPELINE_CREATE_FORM } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const PipelineListPage = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">List Pipelines</h1>
          <Button isOutline href={PIPELINE_CREATE_FORM}>
            Create Pipeline
          </Button>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <PipelineTable />
        </PageLayoutInner>
        {/* For update, pass entityId prop */}
      </div>
    </PageLayout>
  );
};

export default PipelineListPage;
