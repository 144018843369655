import { Route, Routes } from 'react-router-dom';

import ForgotPassword from 'pages/auth/ForgotPassword';
import Login from 'pages/auth/Login';
import LoginActivate from 'pages/auth/LoginActivate';
import LoginActivated from 'pages/auth/LoginActivated';
import Register from 'pages/auth/Register';
import SocialAuthRedirect from 'pages/auth/SocialAuthRedirect';
import SocialRegister from 'pages/auth/SocialRegister';
import Home from 'pages/Home';
import PrivacyPolicyPage from 'pages/legal/PrivacyPolicy';
import TermsAndConditionsPage from 'pages/legal/TermsAndConditions';
import AccountFormPage from 'pages/models/accounts/AccountFormPage';
import AccountListPage from 'pages/models/accounts/AccountListPage';
import GA4BigQueryLinkFormPage from 'pages/models/ga4_bigquery_links/GA4BigQueryLinksFormPage';
import GA4BigQueryLinkListPage from 'pages/models/ga4_bigquery_links/GA4BigQueryLinksListPage';
import PipelineRunViewPage from 'pages/models/pipelineRuns/PipelineRunViewPage';
import PipelineFormPage from 'pages/models/pipelines/PipelineFormPage';
import PipelineListPage from 'pages/models/pipelines/PipelineListPage';
import PipelineAllSourceListPage from 'pages/models/pipelines/PipelineSummaryPage';
import SourceGA4BigQueryFormAdvancedPage from 'pages/models/source_ga4_bigquery/FormPageAdvanced/SourceGA4BigQueryFormPageAdvanced';
import SourceGA4BigQueryFormAdvancedBQPage from 'pages/models/source_ga4_bigquery/FormPageAdvancedBQ/SourceGA4BigQueryFormPageAdvancedBQ';
import SourceGA4BigQueryFormPageAttribution from 'pages/models/source_ga4_bigquery/FormPageAttribution/SourceGA4BigQueryFormPageAttribution';
import SourceGA4BigQueryFormPageChannelGroupings from 'pages/models/source_ga4_bigquery/FormPageChannelGrouping/SourceGA4BigQueryFormPageChannelGroupings';
import SourceGA4BigQueryFormPageColumnsToUnnest from 'pages/models/source_ga4_bigquery/FormPageColumnsToUnnest/SourceGA4BigQueryFormPageColumnsToUnnest';
import SourceGA4BigQueryFormPageConversionsNew from 'pages/models/source_ga4_bigquery/FormPageConversionsNew/SourceGA4BigQueryFormPageConversionsNew';
import SourceGA4BigQueryFormPageEcommerce from 'pages/models/source_ga4_bigquery/FormPageEcommerce/SourceGA4BigQueryFormPageEcommerce';
import SourceGA4BigQueryFormPageEventProperties from 'pages/models/source_ga4_bigquery/FormPageEventProperties/SourceGA4BigQueryFormPageEventProperties';
import SourceGA4BigQueryFormPageRaw from 'pages/models/source_ga4_bigquery/FormPageRaw/SourceGA4BigQueryFormPageRaw';
import SourceGA4BigQuerySessionPropertiesFormPage from 'pages/models/source_ga4_bigquery/FormPageSessionProperties/SourceGA4BigQuerySessionPropertiesFormPage';
import SourceGA4BigQueryFormPageSpamFilter from 'pages/models/source_ga4_bigquery/FormPageSpamFilter/SourceGA4BigQueryFormPageSpamFilter';
import SourceGA4BigQueryFormPageStage1 from 'pages/models/source_ga4_bigquery/FormPageStage1/SourceGA4BigQueryFormPageStage1';
import SourceGA4BigQueryUserPropertiesFormPage from 'pages/models/source_ga4_bigquery/FormPageUserProperties/SourceGA4BigQueryUserPropertiesFormPage';
import SourceGA4BigQueryListPage from 'pages/models/source_ga4_bigquery/ListSourceGA4BigQuery/SourceGA4BigQueryListPage';
import UpgradeSourceGA4BigQueryFormPage from 'pages/models/source_ga4_bigquery/UpgradeSource/UpgradeSourceGA4BigQueryFormPage';
import UserFormPage from 'pages/models/users/UserFormPage';
import UserListPage from 'pages/models/users/UserListPage';
import AccountSettings from 'pages/settings/AccountSettingsPage';
import GoogleAPIAuthCallback from 'pages/settings/permissions/GoogleAPIAuthCallback';
import GoogleAPIAuthRegister from 'pages/settings/permissions/GoogleAPIAuthRegisterPage';
import PlanManagementPage from 'pages/settings/subscription/PlanManagementPage';
import * as route from 'routes/constants';
import authHoc, { authHocLoggedOut } from 'services/auth/authHoc';

// Wrap components with authHoc
const AuthenticatedHomePage = authHoc(Home, true);
const AuthenticatedAccountFormPage = authHoc(AccountFormPage, true);
const AuthenticatedAccountListPage = authHoc(AccountListPage, true);

// User
const AuthenticatedUserListPage = authHoc(UserListPage, true);
const AuthenticatedUserFormPage = authHoc(UserFormPage, true);
const AuthenticatedAccountSettings = authHoc(AccountSettings, true);

// Pipelines
const AuthenticatedPipelineAllSourceListPage = authHoc(PipelineAllSourceListPage, true);
const AuthenticatedPipelineFormPage = authHoc(PipelineFormPage, true);
const AuthenticatedPipelineListPage = authHoc(PipelineListPage, true);

// Pipeline Runs
const AuthenticatedPipelineRunViewPage = authHoc(PipelineRunViewPage, true);

// GA4 BigQuery Export Form
const AuthenticatedSourceGA4BigQueryFormPage = authHoc(SourceGA4BigQueryFormPageStage1, true);
const AuthenticatedSourceGA4BigQueryFormPageEcommerce = authHoc(
  SourceGA4BigQueryFormPageEcommerce,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageConversionsNew = authHoc(
  SourceGA4BigQueryFormPageConversionsNew,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageColumnsToUnnest = authHoc(
  SourceGA4BigQueryFormPageColumnsToUnnest,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageRaw = authHoc(SourceGA4BigQueryFormPageRaw, true);
const AuthenticatedSourceGA4BigQueryListPage = authHoc(SourceGA4BigQueryListPage, true);
const AuthenticatedSourceGA4BigQueryFormPageChannelGroupings = authHoc(
  SourceGA4BigQueryFormPageChannelGroupings,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageSpamFilter = authHoc(
  SourceGA4BigQueryFormPageSpamFilter,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageAttribution = authHoc(
  SourceGA4BigQueryFormPageAttribution,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageAdvancedBQ = authHoc(
  SourceGA4BigQueryFormAdvancedBQPage,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageAdvanced = authHoc(
  SourceGA4BigQueryFormAdvancedPage,
  true
);
const AuthenticatedSourceGA4BigQuerySessionPropertiesFormPage = authHoc(
  SourceGA4BigQuerySessionPropertiesFormPage,
  true
);
const AuthenticatedSourceGA4BigQueryUserPropertiesFormPage = authHoc(
  SourceGA4BigQueryUserPropertiesFormPage,
  true
);
const AuthenticatedSourceGA4BigQueryFormPageEventProperties = authHoc(
  SourceGA4BigQueryFormPageEventProperties,
  true
);

const AuthenticatedUpgradeSourceGA4BigQueryFormPage = authHoc(
  UpgradeSourceGA4BigQueryFormPage,
  true
);

// GA4 BigQuery Links
const AuthenticatedGA4BigQueryLinkFormPage = authHoc(GA4BigQueryLinkFormPage, true);
const AuthenticatedGA4BigQueryLinkListPage = authHoc(GA4BigQueryLinkListPage, true);

// Permissions
const AuthenticatedGoogleAPIAuthRedirect = authHoc(GoogleAPIAuthCallback, true);
const AuthenticatedGoogleAPIAuthRegister = authHoc(GoogleAPIAuthRegister, true);

// Wrap signin with unauth redirects
const UnauthenticatedLogin = authHocLoggedOut(Login);
const UnauthenticatedRegister = authHocLoggedOut(Register);
const UnauthenticatedSocialAuthRedirect = authHocLoggedOut(SocialAuthRedirect);
const UnauthenticatedSocialRegister = authHocLoggedOut(SocialRegister);
const UnauthenticatedLoginActivate = authHocLoggedOut(LoginActivate);
const UnauthenticatedLoginActivated = authHocLoggedOut(LoginActivated);
const UnauthenticatedForgotPassword = authHocLoggedOut(ForgotPassword);

// const TermsAndConditionsPage = authHoc(TermsAndConditionsPage, true);
// const PrivacyPolicyPage = authHoc(PrivacyPolicyPage, true);

const AuthenticatedPlanManagementPage = authHoc(PlanManagementPage, true);

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<AuthenticatedHomePage />} />

    {/* User Auth */}
    <Route path={route.LOGIN_ACTIVATED} element={<UnauthenticatedLoginActivated />} />
    <Route path={route.LOGIN_ACTIVATE} element={<UnauthenticatedLoginActivate />} />
    <Route path={route.LOGIN} element={<UnauthenticatedLogin />} />

    <Route path={route.REGISTER} element={<UnauthenticatedRegister />} />
    <Route path={route.SOCIAL_LOGIN_REDIRECT} element={<UnauthenticatedSocialAuthRedirect />} />
    <Route path={route.SOCIAL_REGISTER} element={<UnauthenticatedSocialRegister />} />
    <Route path={route.FORGOT_PASSWORD} element={<UnauthenticatedForgotPassword />} />

    {/* Legal */}
    <Route path={route.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
    <Route path={route.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

    {/* Accounts */}
    <Route path={route.ACCOUNT_CREATE_FORM} element={<AuthenticatedAccountFormPage />} />
    <Route path={route.ACCOUNT_UPDATE_FORM} element={<AuthenticatedAccountFormPage />} />
    <Route path={route.ACCOUNT_LIST} element={<AuthenticatedAccountListPage />} />

    {/* Pipelines */}
    <Route path={route.PIPELINE_CREATE_FORM} element={<AuthenticatedPipelineFormPage />} />
    <Route path={route.PIPELINE_UPDATE_FORM} element={<AuthenticatedPipelineFormPage />} />
    <Route path={route.PIPELINE_LIST} element={<AuthenticatedPipelineListPage />} />
    <Route
      path={route.PIPELINE_ALL_SOURCE_LIST}
      element={<AuthenticatedPipelineAllSourceListPage />}
    />

    {/* Pipeline Runs */}
    <Route path={route.PIPELINE_RUN_VIEW} element={<AuthenticatedPipelineRunViewPage />} />

    {/* Source GA4 BigQuery */}
    <Route
      path={route.SOURCE_GA4_BIGQUERY_CREATE_FORM}
      element={<AuthenticatedSourceGA4BigQueryFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM}
      element={<AuthenticatedSourceGA4BigQueryFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_COLUMNS_TO_UNNEST}
      element={<AuthenticatedSourceGA4BigQueryFormPageColumnsToUnnest />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ECOMMERCE}
      element={<AuthenticatedSourceGA4BigQueryFormPageEcommerce />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_CONVERSIONS_NEW}
      element={<AuthenticatedSourceGA4BigQueryFormPageConversionsNew />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ATTRIBUTION}
      element={<AuthenticatedSourceGA4BigQueryFormPageAttribution />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_LIST}
      element={<AuthenticatedSourceGA4BigQueryListPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_RAW}
      element={<AuthenticatedSourceGA4BigQueryFormPageRaw />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_CHANNEL_GROUPINGS}
      element={<AuthenticatedSourceGA4BigQueryFormPageChannelGroupings />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_SPAM_FILTER}
      element={<AuthenticatedSourceGA4BigQueryFormPageSpamFilter />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG_BQ}
      element={<AuthenticatedSourceGA4BigQueryFormPageAdvancedBQ />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG}
      element={<AuthenticatedSourceGA4BigQueryFormPageAdvanced />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_SESSION_PROPERTIES}
      element={<AuthenticatedSourceGA4BigQuerySessionPropertiesFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_USER_PROPERTIES}
      element={<AuthenticatedSourceGA4BigQueryUserPropertiesFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM_EVENT_PROPERTIES}
      element={<AuthenticatedSourceGA4BigQueryFormPageEventProperties />}
    />

    {/* GA4 BigQuery Links */}
    <Route
      path={route.GA4_BIGQUERY_LINK_CREATE_FORM}
      element={<AuthenticatedGA4BigQueryLinkFormPage />}
    />
    <Route
      path={route.GA4_BIGQUERY_LINK_UPDATE_FORM}
      element={<AuthenticatedGA4BigQueryLinkFormPage />}
    />
    <Route path={route.GA4_BIGQUERY_LINK_LIST} element={<AuthenticatedGA4BigQueryLinkListPage />} />

    {/* Users */}
    <Route path={route.USER_CREATE_FORM} element={<AuthenticatedUserFormPage />} />
    <Route path={route.USER_UPDATE_FORM} element={<AuthenticatedUserFormPage />} />
    <Route path={route.USER_LIST} element={<AuthenticatedUserListPage />} />

    {/* Pricing */}
    <Route path={route.PLAN_MANAGEMENT} element={<AuthenticatedPlanManagementPage />} />

    {/* Settings */}

    <Route
      path={route.GOOGLE_API_PERMISSIONS_CALLBACK}
      element={<AuthenticatedGoogleAPIAuthRedirect />}
    />
    <Route
      path={route.GOOGLE_API_PERMISSIONS_REGISTER}
      element={<AuthenticatedGoogleAPIAuthRegister />}
    />

    <Route path={route.ACCOUNT_SETTINGS} element={<AuthenticatedAccountSettings />} />

    {/* Add other routes as needed */}

    {/* Add this new route */}
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPGRADE_FORM}
      element={<AuthenticatedUpgradeSourceGA4BigQueryFormPage />}
    />
  </Routes>
);

export default AppRoutes;
