import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  autoSetFields,
  fieldInfoGA4BigQueryLink,
  ga4BigQueryLinkSchemaForm,
} from 'pages/bigquery/schema/ga4BigQueryLinkSchema';
import {
  useAddGA4BigQueryLinkMutation,
  useGetBigQueryDatasetsQuery,
  useGetGA4AccountsQuery,
  useGetGA4BigQueryLinkByIdQuery,
  useGetGoogleBigQueryProjectsQuery,
  useUpdateGA4BigQueryLinkMutation,
} from 'services/rtkApi';

const GA4BigQueryLinkForm = ({ onSuccess }) => {
  const { id } = useParams();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const mutationHook = isUpdate ? useUpdateGA4BigQueryLinkMutation : useAddGA4BigQueryLinkMutation;
  const queryHook = isUpdate ? useGetGA4BigQueryLinkByIdQuery : undefined;

  // Get foreign key data for the form
  const { data: projectsData, isLoading: isLoadingProjects } = useGetGoogleBigQueryProjectsQuery();
  const { data: datasetsData, isLoading: isLoadingDatasets } = useGetBigQueryDatasetsQuery(
    { project_id: selectedProjectId },
    { skip: !selectedProjectId }
  );
  const { data: ga4AccountsData, isLoading: isLoadingGA4Accounts } = useGetGA4AccountsQuery();

  // Setup foreign key options
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['project'],
        options: projectsData?.map((project) => ({ value: project.id, label: project.name })) || [],
      },
      {
        key: ['dataset_location'],
        options:
          datasetsData?.map((dataset) => ({ value: dataset.location, label: dataset.location })) ||
          [],
      },
      {
        key: ['property'],
        options:
          ga4AccountsData?.flatMap((account) =>
            account.properties.map((property) => ({
              value: property.property_id,
              label: `${account.display_name} - ${property.display_name}`,
            }))
          ) || [],
      },
    ],
    [projectsData, datasetsData, ga4AccountsData]
  );

  // Setup loading foreign keys
  const loadingForeignKeys = useMemo(
    () =>
      [
        isLoadingProjects ? ['project'] : [],
        isLoadingDatasets ? ['dataset_location'] : [],
        isLoadingGA4Accounts ? ['property'] : [],
      ].filter((item) => item.length > 0),
    [isLoadingProjects, isLoadingDatasets, isLoadingGA4Accounts]
  );

  const handleFieldChange = useCallback((formValues, changedFields) => {
    if ('project' in changedFields) {
      setSelectedProjectId(changedFields.project);
    }
    if ('property' in changedFields) {
      setSelectedPropertyId(changedFields.property);
    }
  }, []);

  return (
    <CrudForm
      entityId={id}
      key={`ga4_bigquery_link_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={ga4BigQueryLinkSchemaForm}
      fieldInfo={fieldInfoGA4BigQueryLink}
      foreignKeyOptions={foreignKeyOptions}
      loadingForeignKeys={loadingForeignKeys}
      tabs={[
        {
          key: 'Main',
          tabName: 'Main',
          fieldList: [
            'property',
            'project',
            'dataset_location',
            'daily_export_enabled',
            'streaming_export_enabled',
            'fresh_daily_export_enabled',
          ],
          description: (
            <div>
              <p>
                Configure your GA4 BigQuery Link settings here. Select the GA4 property, BigQuery
                project, and dataset location.
              </p>
            </div>
          ),
        },
        {
          key: 'Advanced',
          tabName: 'Advanced',
          fieldList: ['excluded_events', 'export_streams', 'include_advertising_id'],
          description: (
            <div>
              <p>
                Configure advanced settings for your GA4 BigQuery Link, such as excluded events and
                export streams.
              </p>
            </div>
          ),
        },
      ]}
      onSuccess={onSuccess}
      onFieldChange={handleFieldChange}
      selectedProjectId={selectedProjectId}
      selectedPropertyId={selectedPropertyId}
      autoSetFields={autoSetFields}
    />
  );
};

export default GA4BigQueryLinkForm;
