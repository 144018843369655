import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Error boundary that redirects to homepage on error
 */
class ErrorBoundaryBase extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
    const { onError } = this.props;
    if (onError) {
      onError();
    }
  }

  render() {
    return this.props.children;
  }
}

const ErrorBoundaryHomeRedirect = ({ children }) => {
  const navigate = useNavigate();

  const handleError = () => {
    navigate('/', { replace: true });
  };

  return <ErrorBoundaryBase onError={handleError}>{children}</ErrorBoundaryBase>;
};

export default ErrorBoundaryHomeRedirect;
