import { z } from 'zod';

const eventPropertiesSchema = z.array(
  z.object({
    name: z.string(),
  })
);

/**
 * DEFINTIION: Columns to Unnest
 */
export const sourceGA4BigQueryEventPropertiesSchemaForm = z.object({
  hit_properties: eventPropertiesSchema,
});

export const fieldInfoSourceGA4DBTEventProperties = {
  hit_properties: {
    name: 'Hit Properties',
    description:
      "Which extra properties do we want at hit level? E.g. in the pageviews table. This dropdown will often be empty because it won't show any fields we already include.",
    showLabel: false,
    showDescription: false,
    showArrayLabel: false,
    showArrayDescription: false,
    fields: {
      name: { name: 'Field' },
    },
  },
};
