import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { useState } from 'react';

import EmptyStateMultipleActions from 'components/EmptyStateMultipleActions/EmptyStateMultipleActions';
import ListStacked from 'components/ListStacked/ListStacked';

/**
 * FormListProperty component that manages the ListStacked and a simple DetailView with transitions.
 * Shows full EmptyStateMultipleActions when empty, and only actions when items are present.
 *
 * @param {Object} props
 * @param {Array} props.items - List of items to display
 * @param {function} props.children - Function that renders the detail view
 * @param {string} props.emptyStateTitle - Title for empty state
 * @param {string} props.emptyStateDescription - Description for empty state
 * @param {Array} props.emptyStateActions - Actions for empty state
 * @param {string} [props.emptyProjectText] - Text for empty project action
 * @param {Array} [props.bottomActions] - Array of bottom actions
 * @param {function} props.onDeleteItem - Function to delete an item
 */
const FormPropertyList = ({
  items,
  children,
  emptyStateTitle,
  emptyStateDescription,
  emptyStateActions,
  emptyProjectText,
  bottomActions,
  onDeleteItem,
}) => {
  console.log('onDeleteItem', onDeleteItem);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showList, setShowList] = useState(true);

  const handleItemClick = (item) => {
    setShowList(false);
    console.log(item);
    setTimeout(() => setSelectedItem(item), 300);
  };

  const handleBack = () => {
    setSelectedItem(null);
    setShowList(true);
  };

  const handleDelete = (index) => {
    onDeleteItem(index);
  };

  console.log('bottomActions', bottomActions);

  const renderEmptyState = (showFullEmptyState = true) => (
    <EmptyStateMultipleActions
      title={emptyStateTitle}
      description={showFullEmptyState ? emptyStateDescription : null}
      items={emptyStateActions}
      bottomActions={bottomActions}
    />
  );

  if (items.length === 0) {
    return renderEmptyState(true);
  }

  return (
    <div className="relative overflow-x-hidden p-1">
      <Transition
        show={showList}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <ListStacked items={items} onItemClick={handleItemClick} onItemDelete={handleDelete} />
        {/* Add only actions at the bottom when items are present */}
        <div className="mt-8">{renderEmptyState(false)}</div>
      </Transition>

      <Transition
        show={!showList}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="inset-0 relative">
          {selectedItem && (
            <>
              <button
                onClick={handleBack}
                className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100"
                type="button"
                aria-label="Back"
              >
                <ArrowLeftIcon className="h-6 w-6 text-gray-600" />
              </button>
              {children(selectedItem)}
            </>
          )}
        </div>
      </Transition>
    </div>
  );
};

FormPropertyList.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  emptyStateTitle: PropTypes.string.isRequired,
  emptyStateDescription: PropTypes.string.isRequired,
  emptyStateActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    })
  ).isRequired,
  emptyProjectText: PropTypes.string,
  bottomActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      description: PropTypes.string,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    })
  ),
  onDeleteItem: PropTypes.func.isRequired,
};

export default FormPropertyList;
