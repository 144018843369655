/**
 * Main subscription management page that conditionally renders either
 * ChooseYourPlan or ManageYourPlan based on subscription status
 */
import { useState } from 'react';

import Button from 'components/Button/Button';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { useGetCurrentUserQuery, useGetPaddleSubscriptionsQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

import ChooseYourPlan from './components/ChooseYourPlan';
import ManageYourPlan from './components/ManageYourPlan';

export default function PlanManagementPage() {
  const { data: subscriptions, isLoading } = useGetPaddleSubscriptionsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [isForceChoosePlan, setIsForceChoosePlan] = useState(false);

  const hasActiveSubscription = subscriptions?.results?.length > 0;
  const showChoosePlan = isForceChoosePlan || !hasActiveSubscription;

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="flex justify-between items-center w-full">
            <div>
              <h1 className="text-xl font-semibold leading-7 text-gray-900">
                {showChoosePlan ? 'Choose Your Plan' : 'Manage Your Plan'}
              </h1>
            </div>
            {currentUser?.is_superuser && hasActiveSubscription && (
              <Button onClick={() => setIsForceChoosePlan(!isForceChoosePlan)} variant="secondary">
                Switch to {showChoosePlan ? 'Manage Plan' : 'Choose Plan'}
              </Button>
            )}
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip hasFlex={false}>
          {isLoading ? (
            <div>Loading...</div>
          ) : showChoosePlan ? (
            <ChooseYourPlan />
          ) : (
            <ManageYourPlan />
          )}
        </PageLayoutInnerStrip>
      </div>
    </PageLayout>
  );
}
