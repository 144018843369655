// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QBM3caeIQ65213q4RL5p ul{list-style-type:disc}.QBM3caeIQ65213q4RL5p li{margin-left:20px}`, "",{"version":3,"sources":["webpack://./frontend/js/components/Forms/CrudForm/CrudForm.module.scss"],"names":[],"mappings":"AAAA,yBACE,oBAAA,CAGF,yBACE,gBAAA","sourcesContent":[".crudForm ul {\n  list-style-type: disc;\n}\n\n.crudForm li {\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crudForm": `QBM3caeIQ65213q4RL5p`
};
export default ___CSS_LOADER_EXPORT___;
