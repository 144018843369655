import { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';

import GlobalDrawer from 'components/DrawerGlobal/DrawerGlobal';
import ErrorBoundaryHomeRedirect from 'components/ErrorBoundaryHomeRedirect/ErrorBoundaryHomeRedirect';
import ErrorToaster from 'components/ErrorToaster/ErrorToaster';
import TawkTo from 'components/TawkTo/TawkTo';
import AppRoutes from 'routes';
import { rehydrateAuthToken } from 'services/auth/authApiInitialize';
import { setGoogleAPIAuthStatus } from 'services/auth/authSlice';
import api from 'services/rtkApi';
import BaseLayout from 'templates/BaseLayout';
import SentryBoundary from 'utils/SentryBoundary';

import store, { history, persistor } from './store';

const AppInitializer = () => {
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const { data, error } = api.endpoints.getGoogleAPIAuthStatus.useQuery(undefined, {
    skip: !refreshToken,
  });

  useEffect(() => {
    try {
      rehydrateAuthToken();

      if (data) {
        dispatch(setGoogleAPIAuthStatus(data.is_authenticated));
      }
    } catch (err) {
      console.error('Failed to initialize app:', err);
      // Optionally dispatch an error action here if needed
      dispatch(setGoogleAPIAuthStatus(false));
    }
  }, [dispatch, data]);

  if (error) {
    console.error('Failed to fetch Google API status:', error);
    return null;
  }

  return null;
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <SentryBoundary>
          <ErrorBoundaryHomeRedirect>
            <AppInitializer />
            <ErrorToaster />
            <BaseLayout>
              <GlobalDrawer />
              <AppRoutes />
              <TawkTo />
            </BaseLayout>
          </ErrorBoundaryHomeRedirect>
        </SentryBoundary>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
