import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormActionButtons from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';
import SourceGA4BigQueryPageDescription from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryPageDescription';
import SourceGA4BigQueryFormAdvanced from 'pages/models/source_ga4_bigquery/FormPageAdvanced/components/SourceGA4BigQueryFormAdvanced';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryAdvancedFormPage = () => {
  const { id, pipelineId } = useParams();
  const is_update = Boolean(id);
  const page_title = is_update ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline';

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{page_title}</h1>
          {is_update && (
            <SourceGA4BigQueryFormActionButtons pipelineId={pipelineId} sourceGA4BigQueryID={id} />
          )}
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={is_update} activeTab="advanced" />
            <div className="flex-grow mt-5">
              <SourceGA4BigQueryPageDescription pageKey="advanced" />
              <SourceGA4BigQueryFormSection>
                <SourceGA4BigQueryFormAdvanced entityId={id} />
              </SourceGA4BigQueryFormSection>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryAdvancedFormPage;
