import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SQLEditorForm from 'components/SQLEditor/SQLEditorForm';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import {
  useGetPipelineGithubFileQuery,
  useUpdateGithubFileMutation,
  useUpdateSourceGA4BigQueryMutation,
  useValidateGA4CaseStatementMutation,
} from 'services/rtkApi';

/**
 * SourceGA4BigQueryChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {string} props.pipelineId - ID of the associated pipeline
 * @param {string} props.projectId - Google Cloud project ID
 * @param {string} props.dataset - BigQuery dataset name
 */
function SourceGA4BigQueryFormSpamFilterSQL({ id, pipelineId }) {
  const [sql, setSQL] = useState('');
  const dispatch = useDispatch();

  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId);

  /**
   * Get the extra channel grouping macro
   */
  const { data: spamFilterContent, isLoading: isSpamFilterLoading } = useGetPipelineGithubFileQuery(
    {
      id: pipelineId,
      file_path: 'macros/filter_out_event_level_spam.sql',
      macro_name: 'default__filter_out_event_level_spam',
    }
  );
  const [updateGithubFile] = useUpdateGithubFileMutation();

  useEffect(() => {
    if (!isSpamFilterLoading) {
      const content = spamFilterContent?.content || '';
      setSQL(content);
    }
  }, [spamFilterContent, isSpamFilterLoading]);

  // Filter out RECORD types and get all other field names
  const highlightWords = {
    columns:
      tableSchema?.filter((field) => field.type !== 'RECORD')?.map((field) => field.name) || [],
  };
  /**
   * Update the extra channel grouping macro
   */
  const handleSubmit = async () => {
    try {
      const result = await updateGithubFile({
        id: pipelineId,
        file_path: 'macros/filter_out_event_level_spam.sql',
        content: sql,
        macro_name: 'default__filter_out_event_level_spam',
      }).unwrap();
    } catch (error) {
      console.error('Error updating spam filter:', error);
      // Dispatch API error
      if (error.response) {
        dispatch({
          type: 'API_ERROR',
          payload: {
            status: error.response.status,
            data: error.response.data,
          },
        });
      }
    }
  };

  if (isSpamFilterLoading || isLoadingTableSchema) {
    return <LoadingSpinner text="Loading spam filter" size="16" />;
  }

  return (
    <div className="source-ga4-bigquery-spam-filter">
      <SQLEditorForm
        title="GA4 BigQuery Spam Filter"
        initialSQL={sql}
        highlightWords={highlightWords}
        validationMutation={useValidateGA4CaseStatementMutation}
        validationParams={{
          project_id: pipelineData.gcloud_project_id,
          dataset: pipelineData.bq_output_dataset,
          validation_type: 'EVENT_LEVEL_SPAM_FILTER',
        }}
        onSubmit={handleSubmit}
        mutationHook={useUpdateSourceGA4BigQueryMutation}
        entityId={id}
      />
    </div>
  );
}

export default SourceGA4BigQueryFormSpamFilterSQL;
