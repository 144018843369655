import PropTypes from 'prop-types';

/**
 * EmptyState component for displaying when no content is available.
 * @param {Object} props - Component props
 * @param {string} [props.title] - Title text
 * @param {string} [props.description] - Description text
 * @param {Array} props.items - Array of items to display
 * @param {Array} [props.bottomActions] - Array of bottom actions
 * @param {string} bottomActions[].text - Action button text
 * @param {string} bottomActions[].description - Action description
 * @param {string|Function} bottomActions[].action - URL or function for action
 */
const EmptyStateMultipleActions = ({ title, description, items, bottomActions }) => {
  const handleItemClick = (action) => {
    if (typeof action === 'function') {
      action();
    }
  };

  console.log('items', items);
  console.log('bottomActions', bottomActions);

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-8">
      {title && <h2 className="text-base font-semibold leading-6 text-gray-900">{title}</h2>}
      {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
      {items && items.length > 0 ? (
        <ul
          role="list"
          className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
        >
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div
                className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50"
                onClick={() => handleItemClick(item.action)}
                role="button"
                tabIndex={0}
                type="button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleItemClick(item.action);
                  }
                }}
              >
                {item.icon && (
                  <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-gray-100">
                    <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600" />
                  </div>
                )}
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <span>{item.title}</span>
                    <span aria-hidden="true"> &rarr;</span>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="mt-4 text-sm text-gray-500">No items available.</p>
      )}
      {bottomActions?.length > 0 && (
        <div className="mt-6 space-y-4">
          {bottomActions.map((action, idx) => (
            <div key={idx} className="">
              <button
                onClick={() => handleItemClick(action.action)}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                {action.text}
                <span aria-hidden="true"> &rarr;</span>
              </button>
              {action.description && (
                <p className="mt-1 text-sm text-gray-500">{action.description}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

EmptyStateMultipleActions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    })
  ).isRequired,
  bottomActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      description: PropTypes.string,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    })
  ),
};

EmptyStateMultipleActions.defaultProps = {
  items: [],
};

export default EmptyStateMultipleActions;
